import styles from './requests.module.css';
import React, { useState, useEffect } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useNavigate, useLocation } from 'react-router-dom';
import SideBarCard from '../../../components/SideBarCard';
import { SideBar } from '../../../constants';
import logoImage from '../../../assets/images/webimg/logo.svg';
import { makeStyles, useTheme } from '@material-ui/core/styles';

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
</style>

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
    },


    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    formControl: {
        margin: theme.spacing(1),
        width: "80% !important",
        backgroundColor: "#FFFFFF",
        border: "1px solid #000000",
        borderRadius: "5px"
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const numDifferentiation = val => {
    if (val >= 10000000) val = (val / 10000000).toFixed(2) + ' Cr';
    else if (val >= 100000) val = (val / 100000).toFixed(2) + ' Lac';
    else if (val >= 1000) val = (val / 1000).toFixed(2) + ' K';
    return val;
}


<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="#">Navbar</a>
</nav>

function Detailscustomer() {

    const [disableedit, setdisable] = useState(true);
    const [customerdetails, setcustomerdetails] = useState();
    const [totalproperties, settotalproperties] = useState();
    const [networth, setnetworth] = useState();
    const [overallprogress, setoverallprogress] = useState();

    const checkedit = (item) => {
        // console.log(item);
        if (item) {
            for (let i = 0; i < document.querySelectorAll('input[type=text]').length; i++) {
                document.querySelectorAll('input[type=text]')[i].disabled = "";
            }

            for (let i = 0; i < document.querySelectorAll('input[type=number]').length; i++) {
                document.querySelectorAll('input[type=number]')[i].disabled = "";
            }

            for (let i = 0; i < document.getElementsByTagName('select').length; i++) {
                document.getElementsByTagName('select')[i].disabled = "";
            }

        }

        else {

            for (let i = 0; i < document.querySelectorAll('input[type=text]').length; i++) {
                document.querySelectorAll('input[type=text]')[i].disabled = "true";
            }

            for (let i = 0; i < document.querySelectorAll('input[type=number]').length; i++) {
                document.querySelectorAll('input[type=number]')[i].disabled = "true";
            }

            for (let i = 0; i < document.getElementsByTagName('select').length; i++) {
                document.getElementsByTagName('select')[i].disabled = "true";
            }

        }
        document.getElementById("searchbar").disabled = "";
        setdisable(!item);
    }

    const getdetails = () => {

        if (Location.state !== "") {
            fetch("https://dashboard.infinity.indiassetz.com/infinity/api/v1/dashboard/customer/" + idcustomer).then((response) => {
                response.json().then((result) => {
                    setcustomerdetails(result[0].customers[0]);
                    // console.log(customerdetails)
                    // console.log(result[0].customers[0]);
                    // setavailableamenities(result[0].properties[0].amenities_available.split(","));
                    //  setcustomers(result.total_customers);
                    //  setproperties(result.total_properties);
                    //  setrequests(result.total_services);
                    //  setlistings(result.total_services);
                    checkedit(false);
                })
            }).catch(
                (err) => {
                    Navigate("../customers");
                    // console.log("FAIL!")
                }
            )
        }
        else {
            Navigate("../customers");
        }
    }

    const custstatsdetails = () => {

        if (Location.state !== "") {

            fetch("https://dashboard.infinity.indiassetz.com/infinity/api/v1/dashboard/customer/" + idcustomer + "/insights").then((response) => {
                response.json().then((result) => {
                    // console.log(result)
                    settotalproperties(result.total_properties);
                    setnetworth(result.total_networth);
                    setoverallprogress(result.profile_completed);
                    checkedit(false);
                })
            }).catch(
                (err) => {
                    Navigate("../customers");
                    // console.log("FAIL!")
                }
            )
        }
        else {
            Navigate("../customers");
        }
    }
    // const handleChange = (event) => {
    //     setavailableamenities(event.target.value);
    // };

    const Navigate = useNavigate();
    const Location = useLocation();
    const idcustomer = Location.state;
    // console.log(idcustomer);
    const capitalizeFirst = str => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const truncate = (str) => {
		return str?.length > 0 ? str.substr(0, 5) + "%" : str;
	};

    const classes = useStyles();
    const [login, setLogin] = useState(false);
    const [openloader, setOpenLoader] = useState(false)


    const updatefunction = () => {

        const form = document.querySelector('#postform');
        const formData = new FormData(form);
        const formDataObj = {};
        // formData.append('prop_id', idcustomer);
        formData.append('cust_id', idcustomer);
        formData.append('registration_status', "Registered");
        formData.append('is_deleted', false);
        formData.forEach((value, key) => (formDataObj[key] = value));
        // console.log(formDataObj);
        // console.log(JSON.stringify(formDataObj))
        fetch('https://dashboard.infinity.indiassetz.com/infinity/api/v1/dashboard/customers', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formDataObj)
        })
            // Converting to JSON
            // handle based on status
            // .then(response =>
            //     response.json()
            // )

            // Displaying results to console

            .then(response => response.json())
            .then(json => {
                if (json.message == "Customer has been updated successfully") {
                    alert("Successfully updated!");
                    Navigate('../customers');
                }
                else {
                    alert("Customer not updated, Please enter all the fields");
                }
            })

    }

    const deletefunction = () => {
        // console.log(idcustomer);

        if (window.confirm("Are you sure you want to Delete!") === true) {
            // DELETE API
            fetch('https://dashboard.infinity.indiassetz.com/infinity/api/v1/dashboard/customers', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "cust_id": idcustomer,
                    "is_active": false,
                    "is_deleted": true
                }
                )
            })
                .then(response => response.json())

                .then(json => {
                    if (json.message == "Customer has been deleted successfully") {
                        alert("Successfully Deleted!");
                        Navigate('../customers');
                    }
                    else {
                        alert("customer not deleted");
                        // Navigate('../properties');
                    }
                })
        }
        else {
            console.log("Not Deleted");
        }
    }

    useEffect(() => {
        checklogin();
        getdetails();
        custstatsdetails();
        // handletype(0);
    }, [])

    function checklogin() {
        if (sessionStorage.getItem("login")) {
            setOpenLoader(true)
            let token = "Bearer " + sessionStorage.getItem('login');
            fetch("https://valuation-react-api.infinity-staging.indiassetz.com/infinity_api/v1/users/registered-users/", {
                method: "GET",
                headers: {
                    // 'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                    'Authorization': token
                }
                // body: JSON.stringify(this.state.post)
            }).then((response) => {
                response.json().then((result) => {

                    if (result.user_id !== "") {
                        sessionStorage.setItem('name', capitalizeFirst(result.first_name) + " " + capitalizeFirst(result.last_name))

                        setLogin(true);
                        setOpenLoader(false)
                    }
                })
            }).catch(
                (err) => {
                    alert(err.message)
                    setOpenLoader(false)
                    Navigate('../login');
                }
            )
        }
        else {
            Navigate('../login');
        }
    }

    const getShadow = (index) => {
        const obj = {};
        return obj[index];
    }

    const getBackground = (index) => {
        const obj = {};
        return obj[index];
    }

    const Logout = () => {
        sessionStorage.clear();
        setLogin(false);
        Navigate('../login');
    }

    return (

        <div>
            <Backdrop className={classes.backdrop} open={openloader}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <div className={styles.Dashboard}>
                <div className={styles.leftSideScreen}>
                    <img src={logoImage} width="150" height="70" className={styles.leftSideImage} />
                    {SideBar.map((box, index) => {
                        return (
                            <SideBarCard name={box} boxShadow={getShadow(index)} backgroundColor={getBackground(index)} color={(box === "Dashboard" || box === "Customers" || box === "Order History") ? "#171717" : "#171717"} />
                        )
                    })}
                    {/* <div className={styles.buttonStyle}>
                        <div>Add Property</div><div><img className={styles.VectorPlusImage} src={VectorPlusImage} /></div>
                    </div> */}
                    {/* <div className={styles.LogoutBtn}> */}
                    <div><button className={styles.LogoutBtn} onClick={Logout}>Logout</button></div>
                    {/* </div> */}
                </div>

                <div className={styles.rightSideScreen}>
                    <div className={styles.companyDetails}>
                        <div className={styles.edit_delete}>
                            <input id="searchbar" className={styles.searchbar} type="text" placeholder="Search"></input>
                            <button className={styles.addproperties} onClick={() => { Navigate(`../propertydetails/${customerdetails.customer_id}`)}}>+ Properties</button>
                            <button className={styles.Deletebtn} onClick={() => { checkedit(disableedit) }}><i class="fa-solid fa-pencil"></i></button>
                        </div>

                        <div className={styles.flex_container}>
                            <div className={styles.rightdiv}>

                                {/* VIEW ONLY SECTION 
                                
                                THIS SECTION IS VIEWABLE FOR THE USER. TO CHECK EDITABLE SECTION CHECK NEXT COMMENT UNDER THIS
                                */}
                                {customerdetails && disableedit &&
                                    <div className={styles.detailsdiv}>

                                        <div className={styles.detail_top}>
                                            <table className={styles.topleft}>
                                                <tr><td>Client Name</td><td>{capitalizeFirst(customerdetails.first_name + customerdetails.last_name)}</td></tr>
                                                <tr><td>Customer ID</td><td>{customerdetails.customer_id}</td></tr>
                                                <tr><td>Customer Type</td><td>{customerdetails.subscription_type}</td></tr>
                                                <tr><td>Relationship Manager</td><td>{customerdetails.subscription_type}</td></tr>
                                            </table>

                                            <div className={styles.topright}>
                                                <div className={styles.toprightup}>

                                                    <div className={styles.cards}>
                                                        <span> Total Properties</span>
                                                        <div className={styles.dispnum}>{totalproperties}</div>
                                                    </div>
                                                    <div className={styles.cards}>
                                                        <span> Net Worth</span>
                                                        <div className={styles.dispnum}>{numDifferentiation(networth)}</div>
                                                    </div>
                                                    <div className={styles.cards}>
                                                        <span> Overall Progress</span>
                                                        <div className={styles.dispnum}>{truncate(overallprogress)}</div>
                                                    </div>

                                                </div>
                                                <div className={styles.toprightdown}>
                                                    <div className={styles.tprd1}>
                                                        Mobile Number
                                                    </div>
                                                    <div className={styles.tprd2}>
                                                        {customerdetails.mobile}
                                                    </div>
                                                    <div className={styles.tprd3}>
                                                        <i class="fa-solid fa-phone"></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className={styles.detail_mid}>

                                        </div>

                                        <div className={styles.detail_bottom}>
                                            <table className={styles.topleft}>
                                                <tr><td>Residential Status</td><td>{customerdetails.residential_status}</td></tr>
                                                <tr><td>State</td><td>{customerdetails.state}</td></tr>
                                                <tr><td>Income Range</td><td>Gold</td></tr>
                                                <tr><td>eKYC verification</td><td>Verified</td></tr>
                                                <tr><td>eKYC verification date</td><td>01/08/2022</td></tr>
                                                <tr><td>Bank Relationship</td><td>ICICI wealth select</td></tr>
                                                <tr><td>PAN</td><td>{customerdetails.pan_number}</td></tr>
                                                <tr className={styles.descriptionrow}><td style={{ verticalAlign: "top" }}>RM comments</td><td style={{ verticalAlign: "top" }}>Description about the property</td></tr>
                                            </table>
                                            <table className={styles.topleft}>
                                                <tr><td>DOB</td><td>{customerdetails.date_of_birth}</td></tr>
                                                <tr><td>City</td><td>{customerdetails.city}</td></tr>
                                                <tr><td>Registration status</td><td>{customerdetails.registration_status}</td></tr>
                                                <tr><td>eKYC document</td><td>DL</td></tr>
                                                <tr><td>Adhar number</td><td>xxxx-xxxx-xxxx-1234</td></tr>
                                                <tr><td>Investment in RE</td><td>Upto 25%</td></tr>
                                                <tr><td>Income range</td><td>prefer not to mention</td></tr>
                                                <tr><td>Advisory call date</td><td>02/08/2022</td></tr>
                                                <tr><td>Last contact date</td><td>21/08/2022</td></tr>
                                            </table>
                                        </div>
                                    </div>
                                }

                                {/* EDITING, EDIT SECTION *
                                THIS SECTION IS DISPLAYED WHEN PENCIL/EDIT ICON IS CLICKED
                                /}
                                {/* Change NORMAL VALUE TO INPUT FIELDS */}

                                {
                                    customerdetails && !disableedit &&
                                    <div className={styles.detailsdiv}>
                                        <form id='postform'>

                                            <div className={styles.detail_top}>
                                                <table className={styles.topleft}>

                                                    <tr><td>Client Name</td><td><input type="text" defaultValue={customerdetails.first_name}></input></td></tr>
                                                    <tr><td>Customer ID</td><td> <input type="text" defaultValue={customerdetails.customer_id} ></input>  </td></tr>
                                                    <tr><td>Customer Type</td><td>
                                                        <select type="text" defaultValue={customerdetails.subscription_type}>
                                                            <option value="SILVER">SILVER</option>
                                                            <option value="GOLD">GOLD</option>
                                                            <option value="PLATINUM">PLATINUM</option>
                                                            <option value="ACHIEVERS CLUB">ACHIEVERS CLUB</option>
                                                        </select></td></tr>
                                                    <tr><td>Relationship Manager</td><td><input type="text" defaultValue={customerdetails.subscription_type}></input></td></tr>
                                                </table>

                                                <div className={styles.topright}>
                                                    <div className={styles.toprightup}>

                                                        <div className={styles.cards}>
                                                            <span> Total Properties</span>
                                                            <div className={styles.dispnum}>{totalproperties}</div>
                                                        </div>
                                                        <div className={styles.cards}>
                                                            <span> Net Worth</span>
                                                            <div className={styles.dispnum}>{numDifferentiation(networth)}</div>
                                                        </div>
                                                        <div className={styles.cards}>
                                                            <span> Overall Progress</span>
                                                            <div className={styles.dispnum}>{overallprogress}</div>
                                                        </div>

                                                    </div>
                                                    <div className={styles.toprightdown}>
                                                        <div className={styles.tprd1}>
                                                            Mobile Number
                                                        </div>
                                                        <div className={styles.tprd2}>
                                                            <input type="text" defaultValue={9876543210}></input>
                                                        </div>
                                                        <div className={styles.tprd3}>
                                                            <i class="fa-solid fa-phone"></i>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                            {/* <div className={styles.detail_mid}>

                                        </div> */}

                                            <div className={styles.detail_bottom}>
                                                <table className={styles.topleft}>
                                                    <tr><td>Residential Status</td><td>{customerdetails.residential_status}</td></tr>
                                                    <tr><td>State</td><td>{customerdetails.state}</td></tr>
                                                    <tr><td>Income Range</td><td><input type="text" defaultValue={"Gold"}></input></td></tr>
                                                    <tr><td>eKYC verification</td><td>Verified</td></tr>
                                                    <tr><td>eKYC verification date</td><td>01/08/2022</td></tr>
                                                    <tr><td>Bank Relationship</td><td>ICICI wealth select</td></tr>
                                                    <tr><td>PAN</td><td><input type="text" defaultValue={customerdetails.pan_number}></input></td></tr>
                                                    <tr className={styles.descriptionrow}><td style={{ verticalAlign: "top" }}>RM comments</td><td style={{ verticalAlign: "top" }}>
                                                        <textarea style={{ lineHeight: "1.5" }} defaultValue={"Description about the property, Description about the property, Description about the property"} name="message" rows="3" cols="30">
                                                        </textarea>
                                                    </td></tr>
                                                </table>
                                                <table className={styles.topleft}>
                                                    <tr><td>DOB</td><td><input type="text" defaultValue={customerdetails.date_of_birth}></input></td></tr>
                                                    <tr><td>City</td><td><select type="text" defaultValue={customerdetails.city}>
                                                        <option value="" selected disabled>Select City</option>
                                                        <option value="Bangalore">Bangalore</option>
                                                        <option value="Mumbai">Mumbai</option>
                                                        <option value="Delhi">Delhi</option>
                                                        <option value="Hyderabad">Hyderabad</option>
                                                        <option value="Chennai">Chennai</option>
                                                        <option value="Gurgoan">Gurgoan</option>
                                                        <option value="Noida">Noida</option>

                                                    </select></td></tr>
                                                    <tr><td>Registration status</td><td><select type="text" defaultValue={"Gold"}>
                                                        <option value="" selected disabled>Select Registration Status</option>
                                                        <option value="OTP Verification Pending">OTP Verification Pending</option>
                                                        <option value="WIP">WIP</option>
                                                        <option value="Hold">Hold</option>
                                                        <option value="Dropped">Dropped</option>
                                                    </select></td></tr>

                                                    <tr><td>eKYC document</td><td><input type="text" defaultValue={"DL"}></input></td></tr>
                                                    <tr><td>Adhar number</td><td><input type="text" defaultValue={"xxxx-xxxx-xxxx-1234"}></input></td></tr>
                                                    <tr><td>Investment in RE</td><td>Upto 25%</td></tr>
                                                    <tr><td>Income range</td><td><input type="text" defaultValue={"prefer not to mention"}></input></td></tr>
                                                    <tr><td>Advisory call date</td><td><input type="text" defaultValue={"02/08/2022"}></input></td></tr>
                                                    <tr><td>Last contact date</td><td><input type="text" defaultValue={"21/08/2022"}></input></td></tr>
                                                </table>
                                            </div>
                                        </form>
                                    </div>
                                }
                            </div>
                        </div>

                        <div className={styles.button_btm}>
                            <button className={styles.viewproperties} onClick={() => { Navigate(`../customerproperty/${customerdetails.customer_id}`)}}>View Properties</button>
                            <button className={styles.viewservice} onClick={() => {Navigate(`../customerorder/${customerdetails.customer_id}`)}}>View Services</button>
                            {
                                !disableedit &&
                                <button className={styles.Updatebtn} onClick={updatefunction}>Submit</button>
                            }
                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default Detailscustomer;


