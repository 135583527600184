import React from 'react';
import Line from '../../assets/images/Line 56.png';
import Dotted from '../../assets/images/Line 57.png';
import styles from './style.module.css';
import ChartImage from '../../assets/images/Vector 645.png';
import GraphLine from '../../assets/images/High.png';

const Chart = () => {
    return (
        <div className = {styles.chartApp}>
            <div className = {styles.chartInfo}>
                <div>My Net Worth</div>
                <div className = {styles.indicateChart}>
                    <div className = {styles.labelInfo}><span><img src = {Line} /></span>Asset Wealth Growth</div>
                    <div className = {styles.labelInfo}><span><img src = {Dotted} /></span>Estimated Asset Wealth Growth</div>
                </div>
            </div>
            <div className = {styles.numberChart}>
                <div>4.93</div> <div>4.93</div> <div>4.93</div>
            </div>
            <div className = {styles.chartImageParent}>
<img src = {ChartImage} alt = "ChartImage" className = {styles.ChartImage} />
<img src = {GraphLine} alt = "GraphLine" className = {`${styles.GraphLine} ${styles.ChartImage}`} />
            </div>
            <div className = {`${styles.numberChart} ${styles.yearChart}`}>
                <div>2020</div> <div>2021</div> <div>2022</div>
            </div>
        </div>
    )
}

export default Chart
