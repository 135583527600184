import styles from './requests.module.css';
import React, { useState, useEffect } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useNavigate, useLocation } from 'react-router-dom';
import SideBarCard from '../../../components/SideBarCard';
import { SideBar } from '../../../constants';
import logoImage from '../../../assets/images/webimg/logo.svg';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
</style>

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
    },


    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    formControl: {
        margin: theme.spacing(1),
        width: "80% !important",
        backgroundColor: "#FFFFFF",
        border: "1px solid #000000",
        borderRadius: "5px"
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const amenities = [
    'Gym',
    'Swimming Pool',
    'Intercom',
    'Security Camera',
    'Indoor Games',
    'Park',
    'Tennis Court',
    'Event Hall',
    'Security',
    'Club House',
];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="#">Navbar</a>
</nav>

function Detailsrequest() {

    const theme = useTheme();
    // const [customer_id, setcustomer_id] = useState()
    const [infinity_service_id, setinfinity_service_id] = useState()

    // const [property_id, setproperty_id] = useState()
    // const [address, setaddress] = useState()
    // const [amenities_available, setamenities_available] = useState()
    // const [bedroom_count, setbedroom_count] = useState()
    // const [bedroom_size, setbedroom_size] = useState()
    // const [built_up_area, setbuilt_up_area] = useState()
    // const [car_parking, setcar_parking] = useState()
    // const [car_parking_covered, setcar_parking_covered] = useState()
    // const [car_parking_open, setcar_parking_open] = useState()
    // const [city, setcity] = useState()
    // const [country, setcountry] = useState()
    // const [description, setdescription] = useState()
    // const [dimension_length, setdimension_length] = useState()
    // const [dimension_width, setdimension_width] = useState()
    // const [facing_direction, setfacing_direction] = useState()
    // const [floor_count, setfloor_count] = useState()
    // const [furnish_type, setfurnish_type] = useState()
    // const [gps_location, setgps_location] = useState()
    // const [is_checklist_apartment_cost, setis_checklist_apartment_cost] = useState()
    // const [is_checklist_clearances, setis_checklist_clearances] = useState()
    // const [is_checklist_commencement_occupation_certificate, setis_checklist_commencement_occupation_certificate] = useState()
    // const [is_checklist_financial_assistance, setis_checklist_financial_assistance] = useState()
    // const [is_checklist_issuance_receipt, setis_checklist_issuance_receipt] = useState()
    // const [is_checklist_khata_extract, setis_checklist_khata_extract] = useState()
    // const [is_checklist_parental_title_deeds, setis_checklist_parental_title_deeds] = useState()
    // const [is_checklist_plan_license, setis_checklist_plan_license] = useState()
    // const [is_checklist_possession_certificate, setis_checklist_possession_certificate] = useState()
    // const [is_checklist_power_attorney, setis_checklist_power_attorney] = useState()
    // const [is_checklist_sale_construction_agreement, setis_checklist_sale_construction_agreement] = useState()
    // const [is_checklist_sale_deed, setis_checklist_sale_deed] = useState()
    // const [is_checklist_tax_paid_receipt, setis_checklist_tax_paid_receipt] = useState()
    // const [is_hard_deleted, setis_hard_deleted] = useState()
    // const [is_public, setis_public] = useState()
    // const [is_published, setis_published] = useState()
    // const [lift, setlift] = useState()
    // const [plot_area, setplot_area] = useState()
    // const [portal_info, setportal_info] = useState()
    // const [prop_remarks, setprop_remarks] = useState()
    // const [property_type, setproperty_type] = useState()
    // const [property_value_by_customer, setproperty_value_by_customer] = useState()
    // const [state, setstate] = useState()
    // const [survey_no, setsurvey_no] = useState()
    // const [title, settitle] = useState()
    // const [uds, setuds] = useState()
    // const [infinity_updated_at, setinfinity_updated_at] = useState()
    // const [bathroom, setbathroom] = useState()
    // const [ia_valuation, setia_valuation] = useState()
    // const [society_name, setsociety_name] = useState()
    // const [property_category, setproperty_category] = useState()
    // const [additional_facilities, setadditional_facilities] = useState()
    // const [property_purchased, setproperty_purchased] = useState()
    // const [property_purchased_value, setproperty_purchased_value] = useState()
    // const [property_age, setproperty_age] = useState()
    // const [locality, setlocality] = useState()
    // const [landmark, setlandmark] = useState()
    // const [house_no, sethouse_no] = useState()
    // const [area, setarea] = useState()
    // const [website, setwebsite] = useState()
    // const [transaction_type, settransaction_type] = useState()
    const [is_active, setis_active] = useState()
    const [is_deleted, setis_deleted] = useState()
    // const [propertydetails, setpropertydetails] = useState();
    // const [availableamenities, setavailableamenities] = React.useState([]);
    const [disableedit, setdisable] = useState(false);
    // const [servicedetails, setservicedetails] = useState();
    const [servicedetails, setservicedetails] = useState();

    const checkedit = (item) => {
        if (item) {
            for (let i = 0; i < document.querySelectorAll('input[type=text]').length; i++) {
                document.querySelectorAll('input[type=text]')[i].disabled = "";
            }


            for (let i = 0; i < document.querySelectorAll('input[type=number]').length; i++) {
                document.querySelectorAll('input[type=number]')[i].disabled = "";
            }



            for (let i = 0; i < document.getElementsByTagName('select').length; i++) {
                document.getElementsByTagName('select')[i].disabled = "";
            }

        }

        else {

            for (let i = 0; i < document.querySelectorAll('input[type=text]').length; i++) {
                document.querySelectorAll('input[type=text]')[i].disabled = "true";
            }


            for (let i = 0; i < document.querySelectorAll('input[type=number]').length; i++) {
                document.querySelectorAll('input[type=number]')[i].disabled = "true";
            }



            for (let i = 0; i < document.getElementsByTagName('select').length; i++) {
                document.getElementsByTagName('select')[i].disabled = "true";
            }

        }
        setdisable(!item);
    }
    const getdetails = () => {

        if (Location.state !== "") {
            fetch("https://dashboard.infinity.indiassetz.com/infinity/api/v1/dashboard/service/" + idproperty).then((response) => {
                response.json().then((result) => {
                    console.log(response)
                    setservicedetails(result[0].services[0]);
                    // console.log(servicedetails)
                    // console.log(result[0].customers[0]);
                    // setavailableamenities(result[0].properties[0].amenities_available.split(","));
                    //  setcustomers(result.total_customers);
                    //  setproperties(result.total_properties);
                    //  setrequests(result.total_services);
                    //  setlistings(result.total_services);
                    checkedit(false);
                })
            }).catch(
                (err) => {
                    Navigate("../requests");
                    // console.log("FAIL!")
                }
            )
        }
        else {
            Navigate("../requests");
        }
    }
    // const handleChange = (event) => {
    //     setavailableamenities(event.target.value);
    // };

    const Navigate = useNavigate();
    const Location = useLocation();
    const idproperty = Location.state;

    const capitalizeFirst = str => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const classes = useStyles();
    const [login, setLogin] = useState(false);
    const [openloader, setOpenLoader] = useState(false)


    const updatefunction = () => {


        const form = document.querySelector('#postform');
        const formData = new FormData(form);

        const formDataObj = {};
        // formData.append('prop_id', idproperty);
        formData.append('infinity_service_id', idproperty);
        // formData.append('registration_status', "Registered");
        // formData.append('bedroom_count', 3);
        // formData.append('built_up_area', 2100.00);
        // formData.append('bathroom', 3);
        // formData.append('ia_valuation', 0.00);
        // formData.append('country', 'India');
        // formData.append('property_category', 'Residential');
        // formData.append('property_type', 'Apartment');
        // formData.append('is_checklist_apartment_cost', true);
        // formData.append('is_checklist_clearances', true);
        // formData.append('is_checklist_commencement_occupation_certificate', true);
        // formData.append('is_checklist_financial_assistance', true);
        // formData.append('is_checklist_issuance_receipt', true);
        // formData.append('is_checklist_khata_extract', true);
        // formData.append('is_checklist_parental_title_deeds', true);
        // formData.append('is_checklist_plan_license', true);
        // formData.append('is_checklist_possession_certificate', true);
        // formData.append('is_checklist_power_attorney', true);
        // formData.append('is_checklist_sale_construction_agreement', true);
        // formData.append('is_checklist_sale_deed', true);
        // formData.append('is_checklist_tax_paid_receipt', true);
        // formData.append('is_hard_deleted', true);
        // formData.append('is_public', true);
        // formData.append('is_published', true);
        // formData.append('additional_facilities', null);
        // formData.append('property_purchased', null);
        // formData.append('property_purchased_value', null);
        // formData.append('property_age', null);
        // formData.append('locality', null);
        // formData.append('landmark', null);
        // formData.append('house_no', null);
        // formData.append('area', null);
        // formData.append('transaction_type', null);
        // formData.append('lift', "0");
        // formData.append('dimension_width', "10");
        // formData.append('survey_no', "10");
        // formData.append('society_name', "society_name");
        // formData.append('website', "website");
        formData.append('is_active', true);
        formData.append('is_deleted', false);
        // append static data here
        // formData.append('amenities_available', availableamenities);

        // formData.append('keyname',"21")
        // formData.append('keyname',21)
        // formData.append('keyname',null)
        // formData.append('keyname',true)

        formData.forEach((value, key) => (formDataObj[key] = value));
        // console.log(formDataObj);
        console.log(JSON.stringify(formDataObj))
        fetch('https://dashboard.infinity.indiassetz.com/infinity/api/v1/dashboard/services', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formDataObj
            )

        })
            // Converting to JSON
            // handle based on status
            // .then(response =>
            //     response.json()
            // )

            // Displaying results to console

            .then(response => response.json())
            .then(json => {
                console.log(json)
                if (json.message == "service has been updated successfully") {
                    alert("Successfully updated!");
                    Navigate('../requests');
                }
                else {
                    alert("Service not updated, Please enter all the fields");
                }
            })

    }

    const deletefunction = () => {
        console.log(idproperty);

        if (window.confirm("Are you sure you want to Delete!") === true) {
            // DELETE API
            fetch('https://dashboard.infinity.indiassetz.com/infinity/api/v1/dashboard/services', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "infinity_service_id": idproperty,
                    "is_active": false,
                    "is_deleted": true
                }
                )
            })
                .then(response => response.json())


                .then(json => {
                    console.log(json)
                    if (json.message == "Service has been deleted successfully") {
                        alert("Successfully Deleted!");
                        Navigate('../requests');
                    }
                    else {
                        alert("Service not deleted");
                        // Navigate('../properties');
                    }
                })
        }
        else {
            console.log("Not Deleted");

        }
    }

    useEffect(() => {
        checklogin();
        getdetails();
        // handletype(0);
    }, [])

    function checklogin() {
        if (sessionStorage.getItem("login")) {
            setOpenLoader(true)
            let token = "Bearer " + sessionStorage.getItem('login');
            fetch("https://valuation-react-api.infinity-staging.indiassetz.com/infinity_api/v1/users/registered-users/", {
                method: "GET",
                headers: {
                    // 'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                    'Authorization': token
                }
                // body: JSON.stringify(this.state.post)
            }).then((response) => {
                response.json().then((result) => {

                    if (result.user_id !== "") {
                        sessionStorage.setItem('name', capitalizeFirst(result.first_name) + " " + capitalizeFirst(result.last_name))

                        setLogin(true);
                        setOpenLoader(false)
                    }
                })
            }).catch(
                (err) => {
                    alert(err.message)
                    setOpenLoader(false)
                    Navigate('../login');

                }
            )
        }
        else {
            Navigate('../login');
        }
    }

    const getShadow = (index) => {
        const obj = {};
        return obj[index];
    }

    const getBackground = (index) => {
        const obj = {};
        return obj[index];
    }

    const Logout = () => {
        sessionStorage.clear();
        setLogin(false);
        Navigate('../login');
    }

    return (

        <div>
            <Backdrop className={classes.backdrop} open={openloader}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <div className={styles.Dashboard}>
                <div className={styles.leftSideScreen}>
                    <img src={logoImage} width="150" height="70" className={styles.leftSideImage} />
                    {SideBar.map((box, index) => {
                        return (
                            <SideBarCard name={box} boxShadow={getShadow(index)} backgroundColor={getBackground(index)} color={(box === "Dashboard" || box === "Requests" || box === "Order History") ? "#171717" : "#171717"} />
                        )
                    })}
                    {/* <div className={styles.buttonStyle}>
                        <div>Add Property</div><div><img className={styles.VectorPlusImage} src={VectorPlusImage} /></div>
                    </div> */}
                    {/* <div className={styles.LogoutBtn}> */}
                    <div><button className={styles.LogoutBtn} onClick={Logout}>Logout</button></div>
                    {/* </div> */}
                </div>
                <div className={styles.rightSideScreen}>
                    <div className={styles.companyDetails}>
                        <div className={styles.flex_container}>
                            <div className={styles.rightdiv}>


                                {servicedetails &&

                                    <form className={styles.inputform} id="postform" name='postform'>

                                        <div className={styles.labeldiv}>
                                            Service ID
                                            <input type="text" name="infinity_service_id" placeholder='Service Id' defaultValue={servicedetails.infinity_service_id}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Service Status
                                            <input type="text" name="service_status" placeholder='Service Status' defaultValue={servicedetails.service_status}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            status_history
                                            <input type="text" name="status_history" placeholder='Status History' defaultValue={servicedetails.status_history}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Service Comments
                                            <input type="text" name="service_comments" placeholder='Service Comments' defaultValue={servicedetails.service_comments}></input>
                                        </div>

                                        <div className={styles.labeldiv}>
                                            Property Id
                                            <input type="text" name="prop_id" placeholder='Property Id' defaultValue={servicedetails.prop_id}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Customer Id
                                            <input type="text" name="cust_id" placeholder='Cust Id' defaultValue={servicedetails.cust_id}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Client Name
                                            <input type="text" name="client_name" placeholder='Client Name' defaultValue={servicedetails.client_name}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Client Email
                                            <input type="text" name="client_email" placeholder='Client Email' defaultValue={servicedetails.client_email}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Client Phone
                                            <input type="text" name="client_phone" placeholder='Client Phone' defaultValue={servicedetails.client_phone}></input>
                                        </div>
                                    </form>
                                }
                                {/* <button className={styles.Editbtn} onClick={apipost}>Edit</button>
                                <button className={styles.Deletebtn} onClick={apipost}>Delete</button> */}
                            </div>
                        </div>
                        <div className={styles.edit_delete}>
                            <button className={styles.Editbtn} onClick={() => { checkedit(disableedit) }} >Edit</button>
                            {
                                !disableedit &&
                                <button className={styles.Updatebtn} onClick={updatefunction}>Submit</button>

                            }
                            <button className={styles.Deletebtn} onClick={deletefunction}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Detailsrequest;


