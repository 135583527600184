import styles from './requests.module.css';
import React, { useState, useEffect } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useNavigate } from 'react-router-dom';
import SideBarCard from '../../components/SideBarCard';
import { SideBar } from '../../constants';
import logoImage from '../../assets/images/webimg/logo.svg';
import { makeStyles, useTheme } from '@material-ui/core/styles';

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
</style>


const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
    },

    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    formControl: {
        margin: theme.spacing(1),
        width: "80%",
        backgroundColor: "#FFFFFF",
        border: "1px solid #000000",
        borderRadius: "5px"
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};


function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}


<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="#">Navbar</a>

</nav>



function Requestdetails() {

    const theme = useTheme();
    const [availableamenities, setavailableamenities] = React.useState([]);

    const apipost = () => {
        const form = document.querySelector('#postform');
        const formData = new FormData(form);

        const formDataObj = {};
        formData.append('infinity_service_id', 'khot13456');
        formData.append('service_status', 'Active');
        // formData.append('last_name', 'India');
        // formData.append('mobile', '8073976917');
        // formData.append('email', 'anurag.khot@indiassetz.com');
        // formData.append('subscription_type', 'Silver');
        // formData.append('registration_status', 'Registered');
        // formData.append('residential_status', 'Indian');
        // formData.append('date_of_birth', '2022-08-20');
        // formData.append('registration_fee', 0);
        // formData.append('fee_paid_date', '2022-08-20');
        formData.append('is_active', true);

        formData.forEach((value, key) => (formDataObj[key] = value));
        console.log(formDataObj);

        // POST request using fetch()
        fetch("https://dashboard.infinity.indiassetz.com/infinity/api/v1/dashboard/services", {
            // Adding method type
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            // Adding body or contents to send
            body: JSON.stringify(formDataObj),
            // Adding headers to the request
        })

            // Converting to JSON
            .then(response => response.json())

            // Displaying results to console
            .then(json => {
                console.log(json);
                if (json.message != "Service has been created successfully") {
                    alert("Service not created");
                }
                else {
                    Navigate('../requests');
                }
            })
    }

    // const handleChange = (event) => {
    //     setavailableamenities(event.target.value);
    // };

    const Navigate = useNavigate();

    const capitalizeFirst = str => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const classes = useStyles();
    const [login, setLogin] = useState(false);
    const [openloader, setOpenLoader] = useState(false)

    useEffect(() => {
        checklogin();
        // handletype(0);
    }, [])


    function checklogin() {
        if (sessionStorage.getItem("login")) {
            setOpenLoader(true)
            let token = "Bearer " + sessionStorage.getItem('login');
            fetch("https://valuation-react-api.infinity-staging.indiassetz.com/infinity_api/v1/users/registered-users/", {
                method: "GET",
                headers: {
                    // 'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                    'Authorization': token
                }
                // body: JSON.stringify(this.state.post)
            }).then((response) => {
                response.json().then((result) => {

                    if (result.user_id != "") {
                        sessionStorage.setItem('name', capitalizeFirst(result.first_name) + " " + capitalizeFirst(result.last_name))

                        setLogin(true);
                        setOpenLoader(false)
                    }
                })
            }).catch(
                (err) => {
                    alert(err.message)
                    setOpenLoader(false)
                    Navigate('../login');
                }
            )
        }
        else {
            Navigate('../login');
        }
    }

    const getShadow = (index) => {
        const obj = {};
        return obj[index];
    }

    const getBackground = (index) => {
        const obj = {};
        return obj[index];
    }

    const Logout = () => {
        sessionStorage.clear();
        setLogin(false);
        Navigate('../login');
    }

    return (

        <div>
            <Backdrop className={classes.backdrop} open={openloader}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <div className={styles.Dashboard}>
                <div className={styles.leftSideScreen}>
                    <img src={logoImage} width="150" height="70" className={styles.leftSideImage} />
                    {SideBar.map((box, index) => {
                        return (
                            <SideBarCard name={box} boxShadow={getShadow(index)} backgroundColor={getBackground(index)} color={(box === "Dashboard" || box === "Customers" || box === "Order History") ? "#171717" : "#171717"} />
                        )
                    })}
                    {/* <div className={styles.buttonStyle}>
                        <div>Add Property</div><div><img className={styles.VectorPlusImage} src={VectorPlusImage} /></div>
                    </div> */}
                    {/* <div className={styles.LogoutBtn}> */}
                    <div><button className={styles.LogoutBtn} onClick={Logout}>Logout</button></div>
                    {/* </div> */}
                </div>

                <div className={styles.rightSideScreen}>
                    <div className={styles.companyDetails}>
                        <div className={styles.flex_container}>
                            {/* <div className={styles.leftdiv}>
                                <div className={styles.statscard} id="independent" onClick={() => handletype(0)}> 
                                    <img src={residentialImage} className={styles.statsicon} />
                                    <span className={styles.statsdesc1}>Independent</span>
                                    <span className={styles.statsdesc1}>House/Villa</span>
                                </div>

                                <div className={styles.statscard} id="residential" onClick={() => handletype(1)}>
                                    <img src={commercialImage} className={styles.statsicon} />
                                    <span className={styles.statsdesc2}>Residential</span>
                                    <span className={styles.statsdesc2}>Apartment/Flat</span>
                                </div>
                            </div> */}

                            <div className={styles.rightdiv}>

                                {/* <div>
                                <TextField label="Size" id="standard-size-small" defaultValue="Small" size="small" />
                                <TextField label="Size" id="standard-size-normal" defaultValue="Normal" />
                            </div>
                            <div>
                                <TextField
                                label="Size"
                                id="filled-size-small"
                                defaultValue="Small"
                                variant="filled"
                                size="small"
                                />
                                <TextField label="Size" id="filled-size-normal" defaultValue="Normal" variant="filled" />
                            </div> */}
                                {/* <div>
                                <TextField label="Size" id="outlined-size-normal" defaultValue="Property Type" variant="outlined"/>
                                <TextField id="outlined-basic" label="Property Type" variant="outlined" />
                                <TextField label="Dense" id="outlined-margin-dense" defaultValue="Default Value" className={classes.textField} helperText="Some important text"  margin="dense" variant="outlined"/>
                            </div> */}
                                {/* USE MATERIAL UI FOR FORM ELEMENTS */}
                                <form className={styles.inputform} id="postform" name='postform'>


                                    <select name='service_name' style={{ height: 48 }}>
                                        <option value="" selected disabled>Select Service Type</option>
                                        <option value="Ad Posting / Listing">Ad Posting / Listing</option>
                                        <option value="Advisory Services">Advisory Services</option>
                                        <option value="Advisory Services for Buy">Advisory Services for Buy</option>
                                        <option value="Building Plan Sanction">Building Plan Sanction</option>
                                        <option value="Buy">Buy</option>
                                        <option value="Commercial Rental">Commercial Rental</option>
                                        <option value="Comprehensive Tenant Verification">Comprehensive Tenant Verification</option>
                                        <option value="Court Case Check">Court Case Check</option>
                                        <option value="DC conversion">DC conversion</option>
                                        <option value="Digital Title Search">Digital Title Search</option>
                                        <option value="Documents Inspection Report + Title Flow">Documents Inspection Report + Title Flow</option>
                                        <option value="Drafting Lease Agreement / Leave and License Agreement">Drafting Lease Agreement / Leave and License Agreement</option>
                                        <option value="Drafting Legal letters/ Notice/Complaint">Drafting Legal letters/ Notice/Complaint</option>
                                        <option value="Drafting of Letter of Administration">Drafting of Letter of Administration</option>
                                        <option value="Drafting of MOU">Drafting of MOU</option>
                                        <option value="Drafting of Property Gift Deed">Drafting of Property Gift Deed</option>
                                        <option value="Drafting of RERA Complaint">Drafting of RERA Complaint</option>
                                        <option value="Drafting of Will/ Probate">Drafting of Will/ Probate</option>
                                        <option value="Drafting Power of Attorney">Drafting Power of Attorney</option>
                                        <option value="Drafting Rental agreement">Drafting Rental agreement</option>
                                        <option value="Drafting Residential/Commercial Rental Agreement">Drafting Residential/Commercial Rental Agreement</option>
                                        <option value="Drafting Sale Agreement">Drafting Sale Agreement</option>
                                        <option value="Encumbrance Certificate">Encumbrance Certificate</option>
                                        <option value="Electric Meter name transfer">Electric Meter name transfer</option>
                                        <option value="Family Tree">Family Tree</option>
                                        <option value="Finding suitable buyers">Finding suitable buyers</option>
                                        <option value="General Power of Attorney">General Power of Attorney</option>
                                        <option value="Special Power of Attorney">Special Power of Attorney</option>
                                        <option value="Interiors">Interiors</option>
                                        <option value="Ownership Name Transfer">Ownership Name Transfer</option>
                                        <option value="Micro Market">Micro Market</option>
                                        <option value="OC (Occupancy Certificate)">OC (Occupancy Certificate)</option>
                                        <option value="Payments of the Utility bills">Payments of the Utility bills</option>
                                        <option value="Property visit & Supervision by IA team">Property visit & Supervision by IA team</option>
                                        <option value="Rent">Rent</option>
                                        <option value="Repairs and Maintenance">Repairs and Maintenance</option>
                                        <option value="Residential / Commercial Rental Renewal">Residential / Commercial Rental Renewal</option>
                                        <option value="Sale Agreement Vetting">Sale Agreement Vetting</option>
                                        <option value="Sell">Sell</option>
                                        <option value="Site Visit">Site Visit</option>
                                        <option value="Surface Cleaning once a month">Surface Cleaning once a month</option>
                                        <option value="Survey sketch">Survey sketch</option>
                                        <option value="Tax Assessment & Payment">Tax Assessment & Payment</option>
                                        <option value="Tax name Update">Tax name Update</option>
                                        <option value="Temporary power connection for construction site">Temporary power connection for construction site</option>
                                        <option value="Tenancy Management - Residential /Commercial/Any other property">Tenancy Management - Residential /Commercial/Any other property</option>
                                        <option value="Valuation">Valuation</option>
                                        <option value="Water Line connection and transfer">Water Line connection and transfer</option>
                                        <option value="B Khata to A Khata transfer">B Khata to A Khata transfer</option>
                                        <option value="Khata conversion">Khata conversion</option>
                                        <option value="GPA and POA">GPA and POA</option>
                                        <option value="Legal assistance">Legal assistance</option>
                                        <option value="Interior Project management">Interior Project management</option>
                                        <option value="Financial Assistance">Financial Assistance</option>
                                        <option value="Portfolio Advisory">Portfolio Advisory</option>
                                        <option value="Legacy planning">Legacy planning</option>
                                        <option value="Vastu & Energy correction">Vastu & Energy correction</option>
                                        <option value="Flexi Rental">Flexi Rental</option>
                                        <option value="Structured Transactions">Structured Transactions</option>
                                        <option value="Deep Cleaning">Deep Cleaning</option>
                                        <option value="Normal Cleaning">Normal Cleaning</option>
                                        <option value="Painting">Painting</option>
                                        <option value="Gardening">Gardening</option>
                                        <option value="Civil Works">Civil Works</option>
                                        <option value="Carpentry Works">Carpentry Works</option>
                                        <option value="Tile Buffing">Tile Buffing</option>
                                        <option value="Tile Grouting">Tile Grouting</option>
                                        <option value="AC / Gyeser Service">AC / Gyeser Service</option>
                                        <option value="CCTV">CCTV</option>
                                        <option value="HOB & Chimney">HOB & Chimney</option>
                                        <option value="Washing Machine Service">Washing Machine Service</option>
                                        <option value="Plumbing Service">Plumbing Service</option>
                                        <option value="Electrical Service">Electrical Service</option>
                                        <option value="Seepage Treatment">Seepage Treatment</option>
                                        <option value="Sanitation Service">Sanitation Service</option>
                                        <option value="Pest Control">Pest Control</option>
                                        <option value="Solar Maintenance">Solar Maintenance</option>
                                        <option value="Property Management">Property Management</option>
                                        <option value="Bescom name transfer">Bescom name transfer</option>
                                        <option value="Encumbrance Certificate">Encumbrance Certificate</option>
                                        <option value="Occupancy Certificate">Occupancy Certificate</option>
                                        <option value="Water Line Connection And Transfer">Water Line Connection And Transfer</option>
                                        <option value="Short-Term Rent">Short-Term Rent</option>
                                        <option value="Rent (Tenant)">Rent (Tenant)</option>
                                    </select>
                                    <select name='status_history' style={{ height: 48 }}>
                                        <option value="" selected disabled>Select Status</option>
                                        <option value="In Progress">In Progress</option>
                                        <option value="On Hold">On Hold</option>
                                        <option value="Closed">Closed</option>
                                        <option value="Dropped">Dropped</option>
                                        <option value="New">New</option>
                                        <option value="Cancelled">Cancelled</option>
                                    </select>
                                    <input type="text" name="service_comments" placeholder='Service Comments'></input>
                                    <input type="number" name="prop_id" placeholder='Property ID'></input>
                                    <input type="text" name="cust_id" placeholder='Customer ID'></input>
                                    <input type="text" name="client_name" placeholder='Customer Name'></input>
                                    <input type="text" name="client_email" placeholder='Customer Email'></input>
                                    <input type="number" name="client_phone" placeholder='Customer Phone'></input>

                                    {/* <select name='subscription_type' style={{ height: 48 }}>
                                        <option value="" selected disabled>Select Subscription Type</option>
                                        <option value="SILVER">SILVER</option>
                                        <option value="GOLD">GOLD</option>
                                        <option value="PLATINUM">PLATINUM</option>
                                        <option value="ACHIEVERS CLUB">ACHIEVERS CLUB</option>
                                    </select>
                                    <select name='registration_status' style={{ height: 48 }}>
                                        <option value="" selected disabled>Select Registration Status</option>
                                        <option value="OTP Verification Pending">OTP Verification Pending</option>
                                        <option value="WIP">WIP</option>
                                        <option value="Hold">Hold</option>
                                        <option value="Dropped">Dropped</option>
                                    </select>
                                    <select name='residential_status' style={{ height: 48 }}>
                                        <option value="" selected disabled>Select Residential Status</option>
                                        <option value="Resident Indian">Resident Indian</option>
                                        <option value="Non Resident Indian">Non Resident Indian</option>
                                        <option value="NA">NA</option>
                                    </select> */}

                                    {/* <select name="state" style={{ height: 48 }}>
                                        <option value="" selected disabled>Select State</option>
                                        <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                                        <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                        <option value="Assam">Assam</option>
                                        <option value="Bihar">Bihar</option>
                                        <option value="Chandigarh">Chandigarh</option>
                                        <option value="Chhattisgarh">Chhattisgarh</option>
                                        <option value="Dadra and Nagar Haveli and Daman and Diu">Dadra and Nagar Haveli and Daman and Diu</option>
                                        <option value="Delhi">Delhi</option>
                                        <option value="Goa">Goa</option>
                                        <option value="Gujarat">Gujarat</option>
                                        <option value="Haryana">Haryana</option>
                                        <option value="Himachal Pradesh">Himachal Pradesh</option>
                                        <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                        <option value="Jharkhand">Jharkhand</option>
                                        <option value="Karnataka">Karnataka</option>
                                        <option value="Kerala">Kerala</option>
                                        <option value="Ladakh">Ladakh</option>
                                        <option value="Lakshadweep">Lakshadweep</option>
                                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                                        <option value="Maharashtra">Maharashtra</option>
                                        <option value="Manipur">Manipur</option>
                                        <option value="Meghalaya">Meghalaya</option>
                                        <option value="Mizoram">Mizoram</option>
                                        <option value="Nagaland">Nagaland</option>
                                        <option value="Odisha">Odisha</option>
                                        <option value="Puducherry">Puducherry</option>
                                        <option value="Punjab">Punjab</option>
                                        <option value="Rajasthan">Rajasthan</option>
                                        <option value="Sikkim">Sikkim</option>
                                        <option value="Tamil Nadu">Tamil Nadu</option>
                                        <option value="Telangana">Telangana</option>
                                        <option value="Tripura">Tripura</option>
                                        <option value="Union territories">Union territories</option>
                                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                                        <option value="Uttarakhand">Uttarakhand</option>
                                        <option value="West Bengal">West Bengal</option>
                                        <option value="Other">Other</option>
                                    </select>

                                    <select name='city' style={{ height: 50 }}>
                                        <option value="" selected disabled>Select City</option>
                                        <option value="bangalore">Bangalore</option>
                                        <option value="mumbai">Mumbai</option>
                                        <option value="bangalore">Delhi</option>
                                        <option value="mumbai">Hyderabad</option>
                                        <option value="bangalore">Chennai</option>
                                        <option value="mumbai">Gurgoan</option>
                                        <option value="bangalore">Noida</option>
                                    </select> */}

                                    {/* <label for="dob">Date of Birth:</label> */}
                                    {/* <input type="text" id="dob" name="date_of_birth"></input>
                                    <input type="number" name="registration_fee" placeholder='Registration Fee'></input>
                                    <label for="fee_paid_date">Fee Paid Date:</label>
                                    <input type="date" id="fee_paid_date" name="fee_paid_date"></input> */}

                                </form>
                            </div>
                        </div>
                    </div>
                    <div><button className={styles.Nextbtn} onClick={apipost}>SUBMIT</button></div>
                </div>
            </div>
        </div>
    );
}


export default Requestdetails;


