// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_boxButton__VoHx5{\n    display: flex;\n    padding:1rem 2rem;\n}\n.style_boxButton__VoHx5:hover{\n    background-color: #f3f1ef;\n}\n.style_sideBarText__doygd{\n    font-size: 14px;\n    margin-left: 1rem;\n    font-weight: 500;\n}\n.style_badge__pZLez {\n    border-radius: 30%;\n    background-color: #FF8A33;\n    font-size: 11px;\n    color: white;\n    width:18px;\n    height:18px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-left: 8vw;\n}", "",{"version":3,"sources":["webpack://./src/components/SideBarCard/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,iBAAiB;AACrB;AACA;IACI,yBAAyB;AAC7B;AACA;IACI,eAAe;IACf,iBAAiB;IACjB,gBAAgB;AACpB;AACA;IACI,kBAAkB;IAClB,yBAAyB;IACzB,eAAe;IACf,YAAY;IACZ,UAAU;IACV,WAAW;IACX,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,gBAAgB;AACpB","sourcesContent":[".boxButton{\n    display: flex;\n    padding:1rem 2rem;\n}\n.boxButton:hover{\n    background-color: #f3f1ef;\n}\n.sideBarText{\n    font-size: 14px;\n    margin-left: 1rem;\n    font-weight: 500;\n}\n.badge {\n    border-radius: 30%;\n    background-color: #FF8A33;\n    font-size: 11px;\n    color: white;\n    width:18px;\n    height:18px;\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    margin-left: 8vw;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"boxButton": "style_boxButton__VoHx5",
	"sideBarText": "style_sideBarText__doygd",
	"badge": "style_badge__pZLez"
};
export default ___CSS_LOADER_EXPORT___;
