// import logo from './logo.svg';
import './login.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from "react-router-dom";

// import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import companyLogo from '../../../assets/images/webimg/logo.svg';


const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(2),
        },

    },

    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="#">Navbar</a>

</nav>


function Mainlogin() {
    const navigate = useNavigate();

    const capitalizeFirst = str => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const [InitValue, SetValue] = useState({
        "email": "",
        "password": ""
    })
    const handlechange = e => {
        const { name, value } = e.target;
        SetValue({ ...InitValue, [name]: value })
    }

    // LOGIN code above

    const classes = useStyles();
    // const [start_limit, setstart_limit] = useState(0)
    // const [end_limit, setend_limit] = useState(0)
    const [items, setitems] = useState([])
    const [details, setdetails] = useState([])
    const [count, setcount] = useState(0)
    const [minimum, setminimum] = useState(0)
    const [maximum, setmaximum] = useState(0)
    const [average, setaverage] = useState(0)
    const [pagecount, setpagecount] = useState()
    const [itemperpage, setitemperpage] = useState(10)
    const [login, setLogin] = useState(false);
    const [name, setName] = useState('');
    const [openloader, setOpenLoader] = useState(false)
    const [apiUrl, setapiUrl] = useState("");

    useEffect(() => {


        checklogin();

    }, [])


    function checklogin() {
        if (sessionStorage.getItem("login")) {
            setOpenLoader(true)
            let token = "Bearer " + sessionStorage.getItem('login');
            fetch("https://valuation-react-api.infinity-staging.indiassetz.com/infinity_api/v1/users/registered-users/", {
                method: "GET",
                headers: {
                    // 'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                    'Authorization': token
                }
                // body: JSON.stringify(this.state.post)
            }).then((response) => {
                response.json().then((result) => {

                    if (result.user_id != "") {

                        sessionStorage.setItem('name', capitalizeFirst(result.first_name) + " " + capitalizeFirst(result.last_name))
                        setLogin(true);
                        setOpenLoader(true);
                        navigate('../dashboard');

                    }
                })
            }).catch(
                (err) => {
                    alert(err.message)
                    setOpenLoader(false)
                }
            )

        }
    }

    const getuser = () => {
        fetch("https://valuation-react-api.infinity-staging.indiassetz.com/infinity_api/v1/users/login/", {
            method: "POST",
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(InitValue)
        }).then((response) => {
            response.json().then((result) => {

                sessionStorage.setItem('login', result.token);

                if (result.token != "") {
                    checklogin();
                }
                else {
                    document.getElementById("errormessage").innerHTML = result.message;
                }
            })
        })
    }

    function fetchapi(data) {
        if (apiUrl != "") {
            setOpenLoader(true)
            let requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            }

            //   fetch('http://35.200.209.34:8081/valuation/start_valuation', requestOptions)
            //       .then(response => response.json())
            //       .then(data => {
            //       setitems(data.data)
            //       setdetails(data.Details)
            //       setcount(data.count)
            //       setminimum(data.minimum)
            //       setmaximum(data.maximum)
            //       setaverage(data.average)
            //       let count = Math.ceil(data.count/itemperpage)
            //       setpagecount(count)
            //     })
            //   }

            fetch(apiUrl, requestOptions)
                .then(response => response.json())
                .then(data => {
                    setOpenLoader(false)
                    setitems(data.data)
                    setdetails(data.Details)
                    setcount(data.count)
                    setminimum(data.minimum)
                    setmaximum(data.maximum)
                    setaverage(data.average)
                    let count = Math.ceil(data.count / itemperpage)
                    setpagecount(count)
                })
        }

    }

    return (

        <div className="App">

            <Backdrop className={classes.backdrop} open={openloader}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <div className='overallloginform'>
                <div className='loginpage'>
                    <div className='logo'>
                        <img src={companyLogo}></img>
                    </div>
                    <div className='loginform'>
                        <input type="text" placeholder='Email' name='email' onChange={handlechange}></input>
                        <input type="password" placeholder='Password' name='password' onChange={handlechange}></input>
                        <div id="errormessage"></div>
                        <button onClick={getuser}>Login</button>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Mainlogin;


