// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_chartApp__nWG9-{\n    color:#3F434A;\n    padding:1.2rem 1.5rem;\n}\n.style_chartInfo__Occt0{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    font-weight: bold;\n}\n.style_labelInfo__dtxQ1{\n    font-size: 11px;\n}\n.style_labelInfo__dtxQ1 > span{\n    margin-right: 0.7rem;\n}\n.style_numberChart__WHmOB{\n    color:#80164E;\n    font-size: 11px;\n    display: flex;\n    justify-content: space-between;\n    margin:2rem 2rem 0 2rem;\n}\n.style_chartImageParent__zWIyR{\n    position: relative;\n}\n.style_ChartImage__Ttjnk{\n    width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n.style_GraphLine__dH\\+io{\nposition: absolute;\ntop:0;\nleft:0;\n}\n.style_yearChart__DUrv0{\n    color:#8A9099;\n}", "",{"version":3,"sources":["webpack://./src/views/Chart/style.module.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,qBAAqB;AACzB;AACA;IACI,aAAa;IACb,8BAA8B;IAC9B,mBAAmB;IACnB,iBAAiB;AACrB;AACA;IACI,eAAe;AACnB;AACA;IACI,oBAAoB;AACxB;AACA;IACI,aAAa;IACb,eAAe;IACf,aAAa;IACb,8BAA8B;IAC9B,uBAAuB;AAC3B;AACA;IACI,kBAAkB;AACtB;AACA;IACI,WAAW;EACb,YAAY;EACZ,iBAAiB;AACnB;AACA;AACA,kBAAkB;AAClB,KAAK;AACL,MAAM;AACN;AACA;IACI,aAAa;AACjB","sourcesContent":[".chartApp{\n    color:#3F434A;\n    padding:1.2rem 1.5rem;\n}\n.chartInfo{\n    display: flex;\n    justify-content: space-between;\n    align-items: center;\n    font-weight: bold;\n}\n.labelInfo{\n    font-size: 11px;\n}\n.labelInfo > span{\n    margin-right: 0.7rem;\n}\n.numberChart{\n    color:#80164E;\n    font-size: 11px;\n    display: flex;\n    justify-content: space-between;\n    margin:2rem 2rem 0 2rem;\n}\n.chartImageParent{\n    position: relative;\n}\n.ChartImage{\n    width: 100%;\n  height: 100%;\n  object-fit: cover;\n}\n.GraphLine{\nposition: absolute;\ntop:0;\nleft:0;\n}\n.yearChart{\n    color:#8A9099;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"chartApp": "style_chartApp__nWG9-",
	"chartInfo": "style_chartInfo__Occt0",
	"labelInfo": "style_labelInfo__dtxQ1",
	"numberChart": "style_numberChart__WHmOB",
	"chartImageParent": "style_chartImageParent__zWIyR",
	"ChartImage": "style_ChartImage__Ttjnk",
	"GraphLine": "style_GraphLine__dH+io",
	"yearChart": "style_yearChart__DUrv0"
};
export default ___CSS_LOADER_EXPORT___;
