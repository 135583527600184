// import logo from './logo.svg';
import './Valuation.css';
import React, { useState, useEffect } from 'react';
// import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import companyLogo from '../../assets/images/webimg/logo.svg';
import nobrokersLogo from '../../assets/images/webimg/nobrokers.png';
import magicbricksLogo from '../../assets/images/webimg/magicbricks.png';
import squareyardsLogo from '../../assets/images/webimg/squareyards.png';
import commonfloorsLogo from '../../assets/images/webimg/commonfloor.png';
import sulekhaLogo from '../../assets/images/webimg/sulekha.png';
import makaanLogo from '../../assets/images/webimg/makaanlogo.svg';
import { useNavigate } from 'react-router-dom';
import SideBarCard from '../../components/SideBarCard';
import { SideBar } from '../../constants';
import styles from '../Dashboard/style.module.css';
import logoImage from '../../assets/images/webimg/logo.svg';


const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
    },

    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="#">Navbar</a>
</nav>


function Iaai() {

    const Navigate = useNavigate();

    const capitalizeFirst = str => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const numDifferentiation = val => {
        if (val >= 10000000) val = (val / 10000000).toFixed(2) + ' Cr';
        else if (val >= 100000) val = (val / 100000).toFixed(2) + ' Lac';
        else if (val >= 1000) val = (val / 1000).toFixed(2) + ' K';
        return val;
    }

    const [InitValue, SetValue] = useState({
        "email": "",
        "password": ""
    })
    const handlechange = e => {
        const { name, value } = e.target;
        SetValue({ ...InitValue, [name]: value })
    }

    // LOGIN code above

    const initialFilter = {
        city: '',
        locality: '',
        property_type: '',
        pincode: '',
        latitude: '',
        longitude: '',
        bedroom: 0,
        bathroom: 0,
        area: 0,
        society_name: '',
        start_limit: 0,
        end_limit: 10,
    }
    const classes = useStyles();
    const [tokenid, setToken] = useState(sessionStorage.getItem("login"));
    const [requiredfield, setRequired] = useState('');
    const [city, setcity] = useState('')
    const [locality, setlocality] = useState('')
    const [property_type, setproperty_type] = useState('')
    const [pincode, setpincode] = useState('')
    const [latitude, setlatitude] = useState('')
    const [longitude, setlongitude] = useState('')
    const [bedroom, setbedroom] = useState('')
    const [bathroom, setbathroom] = useState('')
    const [area, setarea] = useState(0)
    const [society_name, setsociety_name] = useState('')
    // const [start_limit, setstart_limit] = useState(0)
    // const [end_limit, setend_limit] = useState(0)
    const [items, setitems] = useState([])
    const [details, setdetails] = useState([])
    const [count, setcount] = useState(0)
    const [minimum, setminimum] = useState(0)
    const [maximum, setmaximum] = useState(0)
    const [average, setaverage] = useState(0)
    const [pagecount, setpagecount] = useState()
    const [itemperpage, setitemperpage] = useState(10)
    const [filterobj, setfilterobj] = useState(initialFilter)
    const [login, setLogin] = useState(false);
    const [name, setName] = useState('');
    const [openloader, setOpenLoader] = useState(false)
    const [apiUrl, setapiUrl] = useState("");

    useEffect(() => {
        checklogin();
    }, [])


    function checklogin() {
        if (sessionStorage.getItem("login")) {
            setOpenLoader(true)
            let token = "Bearer " + sessionStorage.getItem('login');
            fetch("https://valuation-react-api.infinity-staging.indiassetz.com/infinity_api/v1/users/registered-users/", {
                method: "GET",
                headers: {
                    // 'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                    'Authorization': token
                }
                // body: JSON.stringify(this.state.post)
            }).then((response) => {
                response.json().then((result) => {

                    if (result.user_id != "") {
                        sessionStorage.setItem('name', capitalizeFirst(result.first_name) + " " + capitalizeFirst(result.last_name))
                        setLogin(true);
                        setOpenLoader(false)
                    }
                })
            }).catch(
                (err) => {
                    alert(err.message)
                    setOpenLoader(false)
                    Navigate('../login');
                }
            )
        }
        else {
            Navigate('../login');
        }
    }

    const getShadow = (index) => {
        const obj = {};
        return obj[index];
    }

    const getBackground = (index) => {
        const obj = {};
        return obj[index];
    }

    const Logout = () => {
        sessionStorage.clear();
        setLogin(false);
        Navigate('../login');
    }

    function fetchapi(data) {
        if (apiUrl != "") {
            setOpenLoader(true)
            let requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify(data)
            }

            fetch(apiUrl, requestOptions)
                .then(response => response.json())
                .then(data => {
                    setOpenLoader(false)
                    setitems(data.data)
                    setdetails(data.Details)
                    setcount(data.count)
                    setminimum(data.minimum)
                    setmaximum(data.maximum)
                    setaverage(data.average)
                    let count = Math.ceil(data.count / itemperpage)
                    setpagecount(count)
                })
        }
    }

    function handlecity(e) {
        setcity(e.target.value)
    }

    // console.log(city);
    function handlelocality(e) {
        setlocality(e.target.value)
    }

    function handleproperty_type(e) {
        setproperty_type(e.target.value)
    }

    function handlepincode(e) {
        setpincode(e.target.value)
    }

    function handlelatitude(e) {
        setlatitude(e.target.value)
    }

    function handlelongitude(e) {
        setlongitude(e.target.value)
    }

    function handlebedroom(e) {
        setbedroom(e.target.value)
    }

    function handlebathroom(e) {
        setbathroom(e.target.value)
    }

    function handlearea(e) {
        setarea(e.target.value)
    }

    function handlesociety_name(e) {
        setsociety_name(e.target.value)
    }

    function handlepagination(e, value) {
        let obj = { ...filterobj, start_limit: (value * itemperpage) - itemperpage, end_limit: value * itemperpage }
        fetchapi(obj)
    }

    function handleapiurl(item) {
        if (item == "rent") {
            document.getElementById("rent").style.backgroundColor = "#74013c";
            document.getElementById("rent").style.color = "#fff9f3";
            document.getElementById("sale").style.backgroundColor = "#fff9f3";
            document.getElementById("sale").style.color = "#74013c";
            setapiUrl("https://valuation-api.infinity-staging.indiassetz.com/rentvaluation/start_rent_valuation")

        }
        else {
            document.getElementById("sale").style.backgroundColor = "#74013c";
            document.getElementById("sale").style.color = "#fff9f3";
            document.getElementById("rent").style.backgroundColor = "#fff9f3";
            document.getElementById("rent").style.color = "#74013c";
            setapiUrl("https://valuation-api.infinity-staging.indiassetz.com/valuation/start_valuation")

        }
    }

    function handlesubmit(e) {
        if (city != "") {
            setRequired("");

            const filter = {
                city: city,
                locality: locality,
                property_type: property_type,
                pincode: pincode,
                latitude: latitude,
                longitude: longitude,
                bedroom: bedroom,
                bathroom: bathroom,
                area: area,
                society_name: society_name,
                start_limit: 0,
                end_limit: 10,
            }
            setfilterobj(filter)
            fetchapi(filter)
        }
        else {
            setRequired("This Field is required");
        }
    }
    return (

        <div className="App">

            <Backdrop className={classes.backdrop} open={openloader}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <div className={styles.Dashboard}>
                <div className={styles.leftSideScreen}>
                    <img src={logoImage} width="150" height="70" className={styles.leftSideImage} />
                    {SideBar.map((box, index) => {
                        return (
                            <SideBarCard name={box} boxShadow={getShadow(index)} backgroundColor={getBackground(index)} color={(box === "Dashboard" || box === "Properties" || box === "Order History") ? "#171717" : "#171717"} />
                        )
                    })}
            <div><button className={styles.LogoutBtn} onClick={Logout}>Logout</button></div>
                    {/* <div className={styles.buttonStyle}>
                        <div>Add Property</div><div><img className={styles.VectorPlusImage} src={VectorPlusImage} /></div>
                    </div> */}
                </div>
                <div className={styles.rightSideScreen}>
                    <nav class="navbar navbar-light">
                        {/* <img src={companyLogo} width="150" height="70" class="logomargin d-inline-block align-top"></img> */}
                        <div className='centernav'><h2>IA - AI</h2><span><h4>Instant valuation & Matching properties</h4></span></div>
                        {/* <div className='detailshead'>
                            <div id="username">{sessionStorage.getItem("name")}</div>
                            <div className='res_form'>
                                <button id='logoutbtn' onClick={Logout}>Logout</button>
                            </div>
                        </div> */}
                    </nav>
                    <div className='loggedin'>
                        <div className="requireddetails"><label><h4>Enter Required Details</h4></label></div>
                        <div className="divallign"> <div className='res_form rentsale'>
                            <button id='rent' onClick={() => handleapiurl("rent")} className="" >Rent</button>
                            <button id='sale' onClick={() => handleapiurl("sale")} className="">Buy</button>
                        </div></div>

                        <div className="divallign"><label ><b>City</b></label>
                            <select style={{ margin: '15px' }} onChange={handlecity}>
                                <option value="" selected disabled>Select City</option>
                                <option value="bangalore">Bangalore</option>
                                <option value="mumbai">Mumbai</option>
                                <option value="bangalore">Delhi</option>
                                <option value="mumbai">Hyderabad</option>
                                <option value="bangalore">Chennai</option>
                            </select>
                        </div>
                        <div> <span className="requiredfield">{requiredfield}</span></div>
                        <div className="divallign"><label><b>Locality</b></label>
                            <input style={{ margin: '15px' }} type='text' value={locality} onChange={handlelocality}></input>
                        </div>

                        <div className="divallign"><label><b>Property Type</b></label>
                            <input style={{ margin: '15px' }} type='text' value={property_type} onChange={handleproperty_type}></input>
                        </div>

                        <div className="divallign"><label><b>Number of Bedrooms</b></label>
                            <input style={{ margin: '15px' }} type='number' value={bedroom} onChange={handlebedroom}></input>
                        </div>

                        <div className="divallign"><label><b>Society/Project Name</b></label>
                            <input style={{ margin: '15px' }} type='text' value={society_name} onChange={handlesociety_name}></input>
                        </div>

                        <br></br>
                        <center>
                            <div className='res_form'>
                                <button onClick={handlesubmit}>Submit</button>
                            </div>
                        </center>

                        <br></br>
                        <ul>
                            <center>
                            </center>
                            {details.length > 0 && <center className="ivbsr"><h3>Instant Valuation</h3></center>}
                            <center className="instantvaluation">
                                {details.length > 0 && details.map(item => (
                                    <div className='responseform linkbox'>
                                        <span>Info : <b>{item.info}</b><br></br></span>
                                        <span>Overall Count : <b>{item.overall_count}</b><br></br></span>
                                        <span>Average Price : <b>{numDifferentiation(item.avg_price)}</b><br></br></span>
                                        <span>Price Per Sqft : <b>{item.price_per_sqft}</b><br></br></span>
                                    </div>
                                ))}
                            </center>
                        </ul>
                        <br></br>

                        <br></br>
                        <ul>
                            {items.length > 0 && <center className="bsr"><h3>Buy-Sell Recommendation</h3></center>}

                            <center>
                                {items.length > 0 && items.map(item => (
                                    <div className='responseform linkbox' onClick={() => window.open(item.property_details_url, '_blank')}>

                                        <div className="topofrec">
                                            <div className='texttopofrec'>
                                                <span>Client ID: <b>{item.cust_id}</b><br></br></span>
                                                <span>Client Name: <b>{capitalizeFirst(item.first_name) + ' ' + capitalizeFirst(item.last_name)}</b><br></br></span>
                                                <span>Client Type: <b>{item.subscription_type}</b><br></br></span>
                                                <span>Property ID: <b>{item.prop_id}</b><br></br></span>
                                            </div>
                                            <div className='imgtopofrec'>
                                                {item.website === 'makaan' && <img src={makaanLogo} className='linkboximg' width={80} height={80}></img>}
                                                {item.website === 'sulekha' && <img src={sulekhaLogo} className='linkboximg' width={100} height={60}></img>}
                                                {item.website === 'nobrokers' && <img src={nobrokersLogo} className='linkboximg' width={100} height={60}></img>}
                                                {item.website === 'magicbricks' && <img src={magicbricksLogo} className='linkboximg' width={100} height={60}></img>}
                                                {item.website === 'squareyards' && <img src={squareyardsLogo} className='linkboximg' width={100} height={60}></img>}
                                                {item.website === 'commonfloors' && <img src={commonfloorsLogo} className='linkboximg' width={100} height={60}></img>}
                                                {item.website === 'indiassetz' && <img src={companyLogo} alt="description of image" className='linkboximg' width={90} height={75}></img>}
                                            </div>
                                        </div>


                                        <div style={{ textAlign: "left" }}>
                                            <span>Title : <b>{item.property_name}</b><br></br> </span>
                                            <span>IA Valuation : <b>{numDifferentiation(item.price)}</b><br></br></span>
                                            <span>Customer Price : <b>{numDifferentiation(item.property_value_by_customer)}</b><br></br></span>
                                            <span>Property Type : <b>{item.property_type}</b><br></br></span>
                                            <span>Bedroom Count: <b>{item.bedroom}</b><br></br></span>
                                            <span>Bathroom Count: <b>{item.bathroom}</b><br></br></span>

                                        </div>
                                    </div>
                                ))}
                            </center>
                        </ul>

                        <div class="center">
                            <div class='pagination_color'>
                                <div className={classes.root}>
                                    <Pagination count={pagecount} onChange={handlepagination} /> <br></br>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Iaai;


