// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_CardStyle__tpF0n{\ncolor:#F5F7FB;\nbox-shadow: 6px 6px 16px #BECDE2, -6px -6px 16px #FFFFFF;\nborder-radius: 30px;\n}", "",{"version":3,"sources":["webpack://./src/components/Card/style.module.css"],"names":[],"mappings":"AAAA;AACA,aAAa;AACb,wDAAwD;AACxD,mBAAmB;AACnB","sourcesContent":[".CardStyle{\ncolor:#F5F7FB;\nbox-shadow: 6px 6px 16px #BECDE2, -6px -6px 16px #FFFFFF;\nborder-radius: 30px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"CardStyle": "style_CardStyle__tpF0n"
};
export default ___CSS_LOADER_EXPORT___;
