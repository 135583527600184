import React from 'react';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Dashboard from './screens/Dashboard';
import Mainlogin from './screens/LoginScreen/Login/login';
import Iaai from './screens/IA-AI/valuation';
import Customers from './screens/Customers';
import Properties from './screens/Properties';
import Requests from './screens/Requests';
// import Propertycategory from './screens/PropertyCRUD/addproperty1';
// import Propertytype from './screens/PropertyCRUD/addproperty2';
import Addcustomer from './screens/CustomerCRUD';
import Propertydetails from './screens/PropertyCRUD/addproperty3';
import Detailsproperty from './screens/PropertyCRUD/detailsproperty';
import Detailscustomer from './screens/CustomerCRUD/detailscustomer';
import Requestdetails from './screens/RequestCRUD';
import Detailsrequest from './screens/RequestCRUD/detailsrequest';
import Customerorder from './screens/Customers/CustomerOrder';
import Customerproperty from './screens/Customers/CustomerProperty';

function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Mainlogin />}></Route>
                <Route path="/dashboard" element={<Dashboard />}></Route>
                <Route path="/login" element={<Mainlogin />}></Route>
                <Route path="/valuation" element={<Iaai />}></Route>
                <Route path="/requests" element={<Requests />}></Route>
                <Route path="/customers" element={<Customers />}></Route>
                <Route path="/properties" element={<Properties />}></Route>
                {/* <Route path="/propertycategory" element={<Propertycategory />}></Route> */}
                {/* <Route path="/propertytype" element={<Propertytype />}></Route> */}
                <Route path="/detailsproperty" element={<Detailsproperty />}></Route>
                <Route path="/addcustomer" element={<Addcustomer />}></Route>
                <Route path="/addrequest" element={<Requestdetails />}></Route>
                <Route path="/detailscustomer" element={<Detailscustomer />}></Route>
                {/* <Route path="/propertydetails" element={<Propertydetails />}> */}
                <Route path='/propertydetails/:custid' element={<Propertydetails/>}></Route>
                {/* </Route> */}
                <Route path="/customerproperty/:custid" element={<Customerproperty />}></Route>
                <Route path="/customerorder/:custid" element={<Customerorder />}></Route>
                <Route path="/detailsrequest" element={<Detailsrequest />}></Route>
            </Routes>
        </BrowserRouter>
    );
}

export default App;
