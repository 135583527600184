import styles from './requests.module.css';
import React, { useState, useEffect } from 'react';
// import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useNavigate } from 'react-router-dom';
import SideBarCard from '../../components/SideBarCard';
import { SideBar } from '../../constants';
import logoImage from '../../assets/images/webimg/logo.svg';
import { makeStyles, useTheme } from '@material-ui/core/styles';


<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
</style>


const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
    },

    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    formControl: {
        margin: theme.spacing(1),
        width: "80%",
        backgroundColor: "#FFFFFF",
        border: "1px solid #000000",
        borderRadius: "5px"
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));


<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="#">Navbar</a>

</nav>


function Customerdetails() {


    const apipost = () => {
        const form = document.querySelector('#postform');
        const formData = new FormData(form);

        const formDataObj = {};
        formData.append('cust_id', 'test12349600');
        formData.append('is_active', true);
        formData.forEach((value, key) => (formDataObj[key] = value));
        // console.log(formDataObj);


        fetch("https://dashboard.infinity.indiassetz.com/infinity/api/v1/dashboard/usercheck", {

            method: "POST",
            headers: { 'Content-Type': 'application/json' },

            body: JSON.stringify({
                "phone": "+91." + formDataObj.mobile,
                "email": formDataObj.email
            }),
            // Adding headers to the request
        })

            // Converting to JSON
            .then(response => response.json())

            // Displaying results to console
            .then(json => {

                if (json.is_available) {
                    alert("customer not created, user already exists");
                }
                else {
                    // ENTER POST FUNCTION HERE FOR ADDING
                    fetch("https://dashboard.infinity.indiassetz.com/infinity/api/v1/dashboard/customers", {
                        // Adding method type
                        method: "POST",
                        headers: { 'Content-Type': 'application/json' },
                        // Adding body or contents to send
                        body: JSON.stringify(formDataObj),
                        // Adding headers to the request
                    })

                        // Converting to JSON
                        .then(response => response.json())

                        // Displaying results to console
                        .then(json => {
                            // console.log(json);
                            if (json.message != "Customer has been created successfully") {
                                alert("customer not created");
                            }
                            else {
                                alert("Successfully Created");
                                Navigate('../customers');
                            }
                        })
                }
            })

        // POST request using fetch()
    }

    // const handleChange = (event) => {
    //     setavailableamenities(event.target.value);
    // };

    const Navigate = useNavigate();

    const capitalizeFirst = str => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const classes = useStyles();
    const [login, setLogin] = useState(false);
    const [openloader, setOpenLoader] = useState(false)

    useEffect(() => {
        checklogin();
        // handletype(0);
    }, [])


    function checklogin() {
        if (sessionStorage.getItem("login")) {
            setOpenLoader(true)
            let token = "Bearer " + sessionStorage.getItem('login');
            fetch("https://valuation-react-api.infinity-staging.indiassetz.com/infinity_api/v1/users/registered-users/", {
                method: "GET",
                headers: {
                    // 'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                    'Authorization': token
                }
                // body: JSON.stringify(this.state.post)
            }).then((response) => {
                response.json().then((result) => {

                    if (result.user_id != "") {
                        sessionStorage.setItem('name', capitalizeFirst(result.first_name) + " " + capitalizeFirst(result.last_name))

                        setLogin(true);
                        setOpenLoader(false)
                    }
                })
            }).catch(
                (err) => {
                    alert(err.message)
                    setOpenLoader(false)
                    Navigate('../login');
                }
            )
        }
        else {
            Navigate('../login');
        }
    }

    const getShadow = (index) => {
        const obj = {};
        return obj[index];
    }

    const getBackground = (index) => {
        const obj = {};
        return obj[index];
    }

    const Logout = () => {
        sessionStorage.clear();
        setLogin(false);
        Navigate('../login');
    }

    return (

        <div>
            <Backdrop className={classes.backdrop} open={openloader}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <div className={styles.Dashboard}>
                <div className={styles.leftSideScreen}>
                    <img src={logoImage} width="150" height="70" className={styles.leftSideImage} />
                    {SideBar.map((box, index) => {
                        return (
                            <SideBarCard name={box} boxShadow={getShadow(index)} backgroundColor={getBackground(index)} color={(box === "Dashboard" || box === "Customers" || box === "Order History") ? "#171717" : "#171717"} />
                        )
                    })}
                    {/* <div className={styles.buttonStyle}>
                        <div>Add Property</div><div><img className={styles.VectorPlusImage} src={VectorPlusImage} /></div>
                    </div> */}
                    {/* <div className={styles.LogoutBtn}> */}
                    <div><button className={styles.LogoutBtn} onClick={Logout}>Logout</button></div>
                    {/* </div> */}
                </div>

                <div className={styles.rightSideScreen}>
                    <div className={styles.companyDetails}>
                        <div className={styles.flex_container}>
                            {/* <div className={styles.leftdiv}>
                                <div className={styles.statscard} id="independent" onClick={() => handletype(0)}> 
                                    <img src={residentialImage} className={styles.statsicon} />
                                    <span className={styles.statsdesc1}>Independent</span>
                                    <span className={styles.statsdesc1}>House/Villa</span>
                                </div>

                                <div className={styles.statscard} id="residential" onClick={() => handletype(1)}>
                                    <img src={commercialImage} className={styles.statsicon} />
                                    <span className={styles.statsdesc2}>Residential</span>
                                    <span className={styles.statsdesc2}>Apartment/Flat</span>
                                </div>
                            </div> */}

                            <div className={styles.rightdiv}>

                                {/* <div>
                                <TextField label="Size" id="standard-size-small" defaultValue="Small" size="small" />
                                <TextField label="Size" id="standard-size-normal" defaultValue="Normal" />
                            </div>
                            <div>
                                <TextField
                                label="Size"
                                id="filled-size-small"
                                defaultValue="Small"
                                variant="filled"
                                size="small"
                                />
                                <TextField label="Size" id="filled-size-normal" defaultValue="Normal" variant="filled" />
                            </div> */}
                                {/* <div>
                                <TextField label="Size" id="outlined-size-normal" defaultValue="Property Type" variant="outlined"/>
                                <TextField id="outlined-basic" label="Property Type" variant="outlined" />
                                <TextField label="Dense" id="outlined-margin-dense" defaultValue="Default Value" className={classes.textField} helperText="Some important text"  margin="dense" variant="outlined"/>
                            </div> */}
                                {/* USE MATERIAL UI FOR FORM ELEMENTS */}
                                <form className={styles.inputform} id="postform" name='postform'>

                                    <input type="text" name="first_name" placeholder='First Name'></input>
                                    <input type="text" name="last_name" placeholder='Last Name'></input>
                                    <input type="text" name="mobile" placeholder='Mobile'></input>
                                    <input type="text" name="email" placeholder='Client Email'></input>
                                    <select name='subscription_type' style={{ height: 48 }}>
                                        <option value="" selected disabled>Select Subscription Type</option>
                                        <option value="SILVER">SILVER</option>
                                        <option value="GOLD">GOLD</option>
                                        <option value="PLATINUM">PLATINUM</option>
                                        <option value="ACHIEVERS CLUB">ACHIEVERS CLUB</option>
                                    </select>
                                    <select name='registration_status' style={{ height: 48 }}>
                                        <option value="" selected disabled>Select Registration Status</option>
                                        <option value="OTP Verification Pending">OTP Verification Pending</option>
                                        <option value="WIP">WIP</option>
                                        <option value="Hold">Hold</option>
                                        <option value="Dropped">Dropped</option>
                                    </select>
                                    <select name='residential_status' style={{ height: 48 }}>
                                        <option value="" selected disabled>Select Residential Status</option>
                                        <option value="Resident Indian">Resident Indian</option>
                                        <option value="Non Resident Indian">Non Resident Indian</option>
                                        <option value="NA">NA</option>
                                    </select>

                                    <input type="number" name="registration_fee" placeholder='Registration Fee'></input>
                                    <label for="dob">Date of Birth:</label>
                                    <input type="date" id="dob" name="date_of_birth"></input>
                                    <label for="fee_paid_date">Fee Paid Date:</label>
                                    <input className={styles.rightSideScreen} type="date" id="fee_paid_date" name="fee_paid_date"></input>


                                    <select name='gender' style={{ height: 48 }}>
                                        <option value="" selected disabled>Select Gender</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                        <option value="Prefer not to disclose">Prefer not to disclose</option>
                                        <option value="Other">Other</option>
                                    </select>

                                    <input type="text" name="address_line_1" placeholder='Address Line 1'></input>
                                    <input type="text" name="address_line_2" placeholder='Address Line 2'></input>

                                    <select name='city' style={{ height: 50 }}>
                                        <option value="" selected disabled>Select City</option>
                                        <option value="Bangalore">Bangalore</option>
                                        <option value="Mumbai">Mumbai</option>
                                        <option value="Delhi">Delhi</option>
                                        <option value="Hyderabad">Hyderabad</option>
                                        <option value="Chennai">Chennai</option>
                                        <option value="Gurgoan">Gurgoan</option>
                                        <option value="Noida">Noida</option>
                                    </select>

                                    <select name="state" style={{ height: 48 }}>
                                        <option value="" selected disabled>Select State</option>
                                        <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                                        <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                        <option value="Assam">Assam</option>
                                        <option value="Bihar">Bihar</option>
                                        <option value="Chandigarh">Chandigarh</option>
                                        <option value="Chhattisgarh">Chhattisgarh</option>
                                        <option value="Dadra and Nagar Haveli and Daman and Diu">Dadra and Nagar Haveli and Daman and Diu</option>
                                        <option value="Delhi">Delhi</option>
                                        <option value="Goa">Goa</option>
                                        <option value="Gujarat">Gujarat</option>
                                        <option value="Haryana">Haryana</option>
                                        <option value="Himachal Pradesh">Himachal Pradesh</option>
                                        <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                        <option value="Jharkhand">Jharkhand</option>
                                        <option value="Karnataka">Karnataka</option>
                                        <option value="Kerala">Kerala</option>
                                        <option value="Ladakh">Ladakh</option>
                                        <option value="Lakshadweep">Lakshadweep</option>
                                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                                        <option value="Maharashtra">Maharashtra</option>
                                        <option value="Manipur">Manipur</option>
                                        <option value="Meghalaya">Meghalaya</option>
                                        <option value="Mizoram">Mizoram</option>
                                        <option value="Nagaland">Nagaland</option>
                                        <option value="Odisha">Odisha</option>
                                        <option value="Puducherry">Puducherry</option>
                                        <option value="Punjab">Punjab</option>
                                        <option value="Rajasthan">Rajasthan</option>
                                        <option value="Sikkim">Sikkim</option>
                                        <option value="Tamil Nadu">Tamil Nadu</option>
                                        <option value="Telangana">Telangana</option>
                                        <option value="Tripura">Tripura</option>
                                        <option value="Union territories">Union territories</option>
                                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                                        <option value="Uttarakhand">Uttarakhand</option>
                                        <option value="West Bengal">West Bengal</option>
                                        <option value="Other">Other</option>
                                    </select>

                                    <input type="text" name="country" placeholder='Country'></input>
                                    <input type="number" name="pin_code" placeholder='Pin Code'></input>
                                    <select name='occupation' style={{ height: 50 }}>
                                        <option value="" selected disabled>Select Occupation</option>
                                        <option value="Salaried">Salaried</option>
                                        <option value="Retired">Retired</option>
                                        <option value="Professional">Professional</option>
                                        <option value="Entrepreneur">Entrepreneur</option>
                                        <option value="Self Employed">Self Employed</option>
                                        <option value="Business">Business</option>
                                        <option value="Other">Other</option>
                                    </select>

                                    <input type="text" name="company" placeholder='Company'></input>
                                    <input type="text" name="designation" placeholder='Designation'></input>

                                    <select name='designation' style={{ height: 50 }}>
                                        <option value="" selected disabled>Select Designation</option>
                                        <option value="Assistant General Manager">Assistant General Manager</option>
                                        <option value="Assistant Manager">Assistant Manager</option>
                                        <option value="Assistant Vice President">Assistant Vice President</option>
                                        <option value="Associate Director">Associate Director</option>
                                        <option value="Associate Vice President">Associate Vice President</option>
                                        <option value="Band 6">Band 6</option>
                                        <option value="Business Analyst">Business Analyst</option>
                                        <option value="CBO">CBO</option>
                                        <option value="CEO">CEO</option>
                                        <option value="City Director">City Director</option>
                                        <option value="Consultant">Consultant</option>
                                        <option value="Content & Digital Marketing Specialist">Content & Digital Marketing Specialist</option>
                                        <option value="COO">COO</option>
                                        <option value="CRE">CRE</option>
                                        <option value="CTO">CTO</option>
                                        <option value="Deputy Vice President">Deputy Vice President</option>
                                        <option value="Digital Marketing Specialist">Digital Marketing Specialist</option>
                                        <option value="Director">Director</option>
                                        <option value="Executive">Executive</option>
                                        <option value="Executive-Backend Operations">Executive-Backend Operations</option>
                                        <option value="Graphic Designer">Graphic Designer</option>
                                        <option value="Head-Product">Head-Product</option>
                                        <option value="House Keeping">House Keeping</option>
                                        <option value="Intern">Intern</option>
                                        <option value="Manager">Manager</option>
                                        <option value="Mentor">Mentor</option>
                                        <option value="MIS Co-ordinator">MIS Co-ordinator</option>
                                        <option value="Office Assistant">Office Assistant</option>
                                        <option value="Relationship Manager">Relationship Manager</option>
                                        <option value="Senior Executive">Senior Executive</option>
                                        <option value="Senior Manager">Senior Manager</option>
                                        <option value="Senior Officer">Senior Officer</option>
                                        <option value="Senior Software Engineer">Senior Software Engineer</option>
                                        <option value="Site Operations Specialist">Site Operations Specialist</option>
                                        <option value="Software Engineer">Software Engineer</option>
                                        <option value="Sr. Relationship Manager">Sr. Relationship Manager</option>
                                        <option value="Trainee">Trainee</option>
                                        <option value="UI/UX Designer">UI/UX Designer</option>
                                        <option value="Vice President">Vice President</option>
                                        <option value="Others">Others</option>
                                    </select>

                                    <input type="text" name="aadhaar_number" placeholder='Aadhaar Number'></input>
                                    <input type="text" name="pan_number" placeholder='Pan Number'></input>

                                    <select name='onboarding_branch' style={{ height: 50 }}>
                                        <option value="" selected disabled>Select Onboarding Branch</option>
                                        <option value="Bangalore">Bangalore</option>
                                        <option value="Hyderabad">Hyderabad</option>
                                        <option value="Professional">Professional</option>
                                        <option value="Entrepreneur">Entrepreneur</option>
                                        <option value="Self Employed">Self Employed</option>
                                        <option value="Business">Business</option>
                                        <option value="Other">Other</option>
                                    </select>

                                    <select name='referral_type' style={{ height: 50 }}>
                                        <option value="" selected disabled>Select Referral Type</option>
                                        <option value="Banks">Banks</option>
                                        <option value="Client Referral">Client Referral</option>
                                        <option value="Indiassetz Employee Referral">Indiassetz Employee Referral</option>
                                        <option value="Individual Referral Partner (IRP)">Individual Referral Partner (IRP)</option>
                                        <option value="Mobile App">Mobile App</option>
                                        <option value="Self">Self</option>
                                        <option value="Social Media">Social Media</option>
                                        <option value="Wealth Management Company">Wealth Management Company</option>
                                        <option value="Website">Website</option>
                                        <option value="Other">Other</option>
                                    </select>

                                    <input type="text" name="referral_partner_name" placeholder='Referral Partner Name'></input>
                                    <input type="text" name="referral_partner_email" placeholder='Referral Partner Email'></input>
                                    <input type="text" name="referral_partner_phone" placeholder='Referral Partner Phone'></input>
                                    <input type="text" name="summary" placeholder='Summary'></input>

                                </form>
                            </div>
                        </div>
                    </div>
                    <div><button className={styles.Nextbtn} onClick={apipost}>SUBMIT</button></div>
                </div>
            </div>
        </div>
    );
}


export default Customerdetails;


