import React, { useState, useEffect } from 'react';
import logoImage from '../../assets/images/webimg/logo.svg';
import Search from '../../components/Search';
import SideBarCard from '../../components/SideBarCard';
import { SideBar } from '../../constants';
import ProfileImage from '../../assets/images/profimg.png';
import CustomerImage from '../../assets/images/Dashboardimg/customer.png';
// import ListingsImage from '../../assets/images/Dashboardimg/listings.png';
import PropertiesImage from '../../assets/images/Dashboardimg/properties.png';
import RequestsImage from '../../assets/images/Dashboardimg/requests.png';
import styles from './style.module.css';
import { useNavigate } from 'react-router-dom';
import Chart from '../../views/Chart';
import Card from '../../components/Card';

const Dashboard = () => {
    const Navigate = useNavigate();
    const [openloader, setOpenLoader] = useState(false)
    const [login, setLogin] = useState(false);

    useEffect(() => {
        checklogin();
        getnumber();
    }, [])

    const capitalizeFirst = str => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    function checklogin() {
        if (sessionStorage.getItem("login")) {
            setOpenLoader(true)
            let token = "Bearer " + sessionStorage.getItem('login');
            fetch("https://valuation-react-api.infinity-staging.indiassetz.com/infinity_api/v1/users/registered-users/", {
                method: "GET",
                headers: {
                    'Authorization': token
                }

            }).then((response) => {
                response.json().then((result) => {

                    if (result.user_id != "") {
                        sessionStorage.setItem('name', capitalizeFirst(result.first_name) + " " + capitalizeFirst(result.last_name))

                        setLogin(true);
                        setOpenLoader(false)
                    }
                    else{
                        Navigate('../login');
                    }
                })
            }).catch(
                (err) => {
                    setOpenLoader(false)
                    Navigate('../login');
                }
            )
        }
        else{
            Navigate('../login');
        }
    }

    const Logout = () => {
        sessionStorage.clear();
        setLogin(false);
        Navigate('../login');
    }

    const getShadow = (index) => {
        const obj = {};
        return obj[index];
    }

    const getBackground = (index) => {
        const obj = {};
        return obj[index];
    }

    // THIS IS DASHBOARD FUNCTION
    const [customers, setcustomers] = useState(0)
    const [properties, setproperties] = useState(0)
    const [requests, setrequests] = useState(0)
    const [listings, setlistings] = useState(0)
    const getnumber = ()=>{
        fetch("https://dashboard.infinity.indiassetz.com/infinity/api/v1/dashboard/total_count").then((response) => {
            response.json().then((result) => {

            //  setDemoNum(result[0]);
             setcustomers(result.total_customers);
             setproperties(result.total_properties);
             setrequests(result.total_services);
             setlistings(result.total_services);
               
            })
        }).catch(
            (err) => {
                console.log("FAIL!")
            }
        )
    }

    return (
                <div className={styles.Dashboard}>
                    <div className={styles.leftSideScreen}>
                        <img src={logoImage} width="150" height="70" className={styles.leftSideImage} />
                        {SideBar.map((box, index) => {
                            return (
                                <SideBarCard name={box} boxShadow={getShadow(index)} backgroundColor={getBackground(index)} color={(box === "Dashboard" || box === "Properties" || box === "Order History") ? "#171717" : "#171717"} />
                            )
                        })}
                        {/* <div className={styles.LogoutBtn}> */}
                            <div><button className={styles.LogoutBtn} onClick={Logout}>Logout</button></div>
                        {/* </div> */}
                    </div>
                    <div className={styles.rightSideScreen}>
                        <div className={styles.navbar}>
                            <Search />
                            <div className={styles.NavRightSide}>

                                <button className={styles.NameBtn} onClick={() => { Navigate('../addcustomer'); }}>Add Customer</button>
                                <div><img src={ProfileImage} alt="ProfileImage" className={styles.ProfileImage} /></div>
                                {/* <div className={styles.NameBtn}>
                                    <div><b>{sessionStorage.getItem("name")} </b></div>
                                </div> */}
                            </div>
                        </div>
                        <div className={styles.aboutPageOwner}>
                            <div><h5>Welcome, </h5><span><h3>{sessionStorage.getItem("name")}</h3></span></div>
                            {/* <div className={styles.aboutOwner}>Welcome to your own customized Real Estate experience</div> */}
                        </div>
                        <div className={styles.companyDetails}>
                           <div className={styles.stats}>

                            <div className={styles.statscard+' '+styles.dashboardcustomercard}>
                                <img src={CustomerImage} className={styles.statsicon} />
                                <span className={styles.statsdesc}>Number of customers</span>
                                <span className={styles.statsnum}>{customers && customers}</span>
                            </div>

                            <div className={styles.statscard+' '+styles.dashboardpropertycard}>
                                <img src={PropertiesImage} className={styles.statsicon} />
                                <span className={styles.statsdesc}>Active Properties</span>
                                <span className={styles.statsnum}>{properties && properties}</span>
                            </div>

                            <div className={styles.statscard+' '+styles.dashboardlistingcard}>
                                <img src={RequestsImage} className={styles.statsicon} />
                                <span className={styles.statsdesc}>Actionable Requests</span>
                                <span className={styles.statsnum}>{requests && requests}</span>
                            </div>

                            {/* <div className={styles.statscard+' '+styles.dashboardactivitycard}>
                                <img src={ListingsImage} className={styles.statsicon} />
                                <span className={styles.statsdesc}>New Listings</span>
                                <span className={styles.statsnum}>{listings && listings}</span>
                            </div> */}
                           </div>
                           
                            <Card height={"40vh"} width={"75vw"} >
                                <Chart />
                            </Card>
                        </div>
                        {/* <div className={styles.labelAbout}>
                            <div>Your Investments</div><div>Gross Rental Incomes</div>
                        </div>
                        <div className={styles.Cards}>
                            <Card height={"35vh"} width={"24vw"}><Progress text={"Residential"} array={["3BHK", "3BHK", "3BHK", "2BHK"]} type={"Apartment"} /></Card>
                            <div className={styles.marginCards}><Card height={"41vh"} width={"24vw"}><Progress text={"Commercial"} array={["10 Floor", "6 Floor"]} type={"Building"} /></Card></div>
                            <Card height={"35vh"} width={"24vw"} ><Progress text={"Commercial"} array={["Residential", "Commercial"]} type={""} content={true} /></Card>
                        </div>
                        <div className={styles.labelAbout}>
                            <div>Your Properties</div><div className={styles.viewText}>View All</div>
                        </div>
                        <div className={styles.imageContent}>
                            <Card height={"30vh"} width={"36vw"}><ImageContent text={"The Symphony"} /></Card>
                            <Card height={"30vh"} width={"36vw"}><ImageContent text={"By The Lake"} /></Card>
                        </div>
                        <div className={styles.labelAbout}>
                            <div>Property Taxes</div><div className={styles.viewText}>View All</div>
                        </div>
                        <div className={`${styles.labelAbout} ${styles.propertstyle}`}>Pay your Property taxes Hassle Free with Indiassetz</div>
                        <div className={styles.cardGroup}>
                            <Card height={"15vh"} width={"9vw"} borderRadius={"15px"} alignMiddle={true}><div className={styles.smallCardContent}><img className={styles.imageContainer} src={MobileImage} /><div>Pay Taxes</div></div> </Card>
                            <div className={styles.middleCard}><Card borderRadius={"15px"} height={"15vh"} alignMiddle={true} width={"9vw"}><div className={styles.smallCardContent}><img className={styles.imageContainer} src={GrowthImage} /><div>Tax History</div></div></Card></div>
                            <Card height={"15vh"} width={"9vw"} borderRadius={"15px"} alignMiddle={true}><div className={styles.smallCardContent}><img className={styles.imageContainer} src={CalendarImage} /><div>Tax Calendar</div></div></Card>
                        </div>
                        <div className={styles.groupOfCards}>
                            <div className={styles.leftSideCards}>
                                <div className={styles.insightLabel}>Insights
                                    <div className={`${styles.insightText}`}>Smart Insights Customized for you</div>
                                    <div className={styles.leftCard}>
                                        <Card height={"30vh"} width={"86%"} alignMiddle={true}><div className={styles.LeftCardContent}><div>Tibets</div><img className={styles.leftCardImages} src={MoneyImage} /><div className={styles.leftContent}>Increase Your Wealth By Diversifying Your Portfolio</div></div></Card>
                                        <Card height={"30vh"} width={"86%"} alignMiddle={true} marginLeft={"1.5rem"}><div className={styles.LeftCardContent}><div>Invest Less Earn More</div><img className={styles.leftCardImages} src={TreeImage} /><div className={styles.leftContent}>Invest In Real Estates Like Stocks</div></div></Card>
                                    </div>
                                </div>
                            </div>
                            <div className={styles.rightSideCards}>
                                <div className={styles.rightSideLabel}>
                                    <div>Designed For You</div><div className={styles.viewText}>Explore more</div>
                                </div>
                                <div className={`${styles.insightText}`}>Our AI Enabled Smart Suggestions Specially For You</div>
                                <div className={styles.rightCards}>
                                    <Card height={"13.5vh"} width={"100%"} boxShadow={"inset 4px 4px 8px rgba(190, 205, 226, 0.5), inset -5px -5px 30px #FFFFFF"} alignMiddle={true} borderRadius={"16px"}><CalVal type={"Commercial"} /></Card>
                                    <Card height={"13.5vh"} width={"100%"} boxShadow={"inset 4px 4px 8px rgba(190, 205, 226, 0.5), inset -5px -5px 30px #FFFFFF"} alignMiddle={true} borderRadius={"16px"} marginTop={"1rem"}><CalVal type={"Residential"} /></Card>
                                </div>
                            </div> */}
                        {/* </div> */}
                    </div>
                </div>
    )
}

export default Dashboard
