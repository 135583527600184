import React from 'react';
import MailImage from '../../assets/images/Mail.png';
import GridImage from '../../assets/images/Grid.png';
import styles from './style.module.css';
import {useNavigate} from 'react-router-dom';

const SideBarCard = ({name,backgroundColor,boxShadow,color}) => {
    const Navigate = useNavigate()
    const handleclick = ()=>{
        if(name==="IA-AI"){
            Navigate('../valuation');
            // Navigate("https://valuation.infinity-staging.indiassetz.com/")  
        }
        if(name==="Dashboard"){
            Navigate('../dashboard');
        }
        if(name==="Customers"){
            Navigate('../customers');
        }
        if(name==="Properties"){
            Navigate('../properties');
        }
        if(name==="Orders"){
            Navigate('../requests');
        }
        // if(name==="Add Property"){
        //     Navigate('../propertydetails');
        // }
        // if(name==="Add Customer"){
        //     Navigate('../addcustomer')
        // }
        // if(name==="Add Service"){
        //     Navigate('../addrequest')
        // }
        if(name==="Call Back"){
            Navigate('../dashboard');
        }
        if(name==="Payments"){
            Navigate('../dashboard');
        }
    }
    return (
        <div onClick={handleclick} className = {styles.boxButton} style = {{backgroundColor:backgroundColor,boxShadow:boxShadow,color:color, cursor:'pointer'}}>
           <img src = {name === "Mail" ? MailImage : GridImage} />
           <div className = {styles.sideBarText}>{name}</div>
          
        </div>
    )
}

export default SideBarCard
