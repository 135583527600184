import React from 'react';
import styles from './style.module.css';

const Card = (props) => {
    return (
        <div className = {styles.CardStyle} style = {{minHeight:props.height,height:props.heightEst,marginLeft:props.marginLeft,width:props.width,borderRadius:props.lessBorderRadius && "10px",display:props.alignMiddle && "flex",alignItems:props.alignMiddle && "center",justifyContent:props.alignMiddle && "center",boxShadow:props.boxShadow,borderRadius:props.borderRadius,marginTop:props.marginTop}}>
            {props.children}
        </div>
    )
}

export default Card
