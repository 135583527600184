// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".style_SearchBox__Ed2kx{\n    width:20vw;\n    height:5vh;\n    background: linear-gradient(90deg, #F1F1F1 -1.14%, rgba(241, 241, 241, 0) 105.04%);\nborder-radius: 10px;\nborder:none;\noutline: none;\n}\n.style_imageSize__kHZ0W{\n    height:1.8vh;\nposition: relative;\nleft:8%;\ntop:20%\n}\n.style_SearchBox__Ed2kx::-webkit-input-placeholder {\n   padding-left: 11%;\n}", "",{"version":3,"sources":["webpack://./src/components/Search/style.module.css"],"names":[],"mappings":"AAAA;IACI,UAAU;IACV,UAAU;IACV,kFAAkF;AACtF,mBAAmB;AACnB,WAAW;AACX,aAAa;AACb;AACA;IACI,YAAY;AAChB,kBAAkB;AAClB,OAAO;AACP;AACA;AACA;GACG,iBAAiB;AACpB","sourcesContent":[".SearchBox{\n    width:20vw;\n    height:5vh;\n    background: linear-gradient(90deg, #F1F1F1 -1.14%, rgba(241, 241, 241, 0) 105.04%);\nborder-radius: 10px;\nborder:none;\noutline: none;\n}\n.imageSize{\n    height:1.8vh;\nposition: relative;\nleft:8%;\ntop:20%\n}\n.SearchBox::-webkit-input-placeholder {\n   padding-left: 11%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"SearchBox": "style_SearchBox__Ed2kx",
	"imageSize": "style_imageSize__kHZ0W"
};
export default ___CSS_LOADER_EXPORT___;
