import styles from './requests.module.css';
import React, { useState, useEffect } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useNavigate } from 'react-router-dom';
import SideBarCard from '../../../components/SideBarCard';
import { SideBar } from '../../../constants';
import logoImage from '../../../assets/images/webimg/logo.svg';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
</style>

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
    },

    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    formControl: {
        margin: theme.spacing(1),
        width: "80%",
        backgroundColor: "#FFFFFF",
        border: "1px solid #000000",
        borderRadius: "5px"
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const idcustomer = Location.state;

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const amenities = [
    'Gym',
    'Swimming Pool',
    'Intercom',
    'Security Camera',
    'Indoor Games',
    'Park',
    'Tennis Court',
    'Event Hall',
    'Security',
    'Club House',
];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="#">Navbar</a>

</nav>

function Propertydetails() {

    const theme = useTheme();
    const [availableamenities, setavailableamenities] = React.useState([]);

    const apipost = () => {
        const form = document.querySelector('#postform');
        const formData = new FormData(form);

        const formDataObj = {};
        formData.append('prop_id', 9988);
        formData.append('cust_id', 'test123456789');
        formData.append('ia_valuation', 0.00);
        formData.append('country', 'India');
        formData.append('infinity_updated_at', new Date().toISOString());
        formData.append('is_active', true);
        formData.append('is_deleted', false);
        // append static data here
        formData.append('amenities_available', availableamenities);

        // formData.append('keyname',"21")
        // formData.append('keyname',21)
        // formData.append('keyname',null)
        // formData.append('keyname',true)

        formData.forEach((value, key) => (formDataObj[key] = value));
        console.log(JSON.stringify(formDataObj));

        // POST request using fetch()
        fetch("https://dashboard.infinity.indiassetz.com/infinity/api/v1/dashboard/properties", {
            // Adding method type
            method: "POST",
            headers: { 'Content-Type': 'application/json' },
            // Adding body or contents to send
            body: JSON.stringify(formDataObj),
            // Adding headers to the request
        })
            // Converting to JSON
            .then(response => response.json())

            // Displaying results to console
            .then(json => {
                // console.log(json);
                if (json.message != "property has been created successfully") {
                    alert("property not created");
                }
                else {
                    Navigate('../properties');
                }
            })
    }

    const handleChange = (event) => {
        setavailableamenities(event.target.value);
    };

    const Navigate = useNavigate();

    const capitalizeFirst = str => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const classes = useStyles();
    const [login, setLogin] = useState(false);
    const [openloader, setOpenLoader] = useState(false)

    useEffect(() => {
        checklogin();
        // handletype(0);
    }, [])


    function checklogin() {
        if (sessionStorage.getItem("login")) {
            setOpenLoader(true)
            let token = "Bearer " + sessionStorage.getItem('login');
            fetch("https://valuation-react-api.infinity-staging.indiassetz.com/infinity_api/v1/users/registered-users/", {
                method: "GET",
                headers: {
                    // 'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                    'Authorization': token
                }
                // body: JSON.stringify(this.state.post)
            }).then((response) => {
                response.json().then((result) => {

                    if (result.user_id != "") {
                        sessionStorage.setItem('name', capitalizeFirst(result.first_name) + " " + capitalizeFirst(result.last_name))

                        setLogin(true);
                        setOpenLoader(false)
                    }
                })
            }).catch(
                (err) => {
                    alert(err.message)
                    setOpenLoader(false)
                    Navigate('../login');
                }
            )
        }
        else {
            Navigate('../login');
        }
    }

    const getShadow = (index) => {
        const obj = {};
        return obj[index];
    }

    const getBackground = (index) => {
        const obj = {};
        return obj[index];
    }

    const Logout = () => {
        sessionStorage.clear();
        setLogin(false);
        Navigate('../login');
    }

    return (

        <div>
            <Backdrop className={classes.backdrop} open={openloader}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <div className={styles.Dashboard}>
                <div className={styles.leftSideScreen}>
                    <img src={logoImage} width="150" height="70" className={styles.leftSideImage} />
                    {SideBar.map((box, index) => {
                        return (
                            <SideBarCard name={box} boxShadow={getShadow(index)} backgroundColor={getBackground(index)} color={(box === "Dashboard" || box === "Properties" || box === "Order History") ? "#171717" : "#171717"} />
                        )
                    })}
                    {/* <div className={styles.buttonStyle}>
                        <div>Add Property</div><div><img className={styles.VectorPlusImage} src={VectorPlusImage} /></div>
                    </div> */}
                    {/* <div className={styles.LogoutBtn}> */}
                    <div><button className={styles.LogoutBtn} onClick={Logout}>Logout</button></div>
                    {/* </div> */}
                </div>

                <div className={styles.rightSideScreen}>
                    <div className={styles.companyDetails}>
                        <div className={styles.flex_container}>

                            <div className={styles.rightdiv}>

                                {/* USE MATERIAL UI FOR FORM ELEMENTS */}
                                <form className={styles.inputform} id="postform" name='postform'>

                                    <select name="ownership" placeholder='Ownership Type' style={{ height: 50 }}>
                                        <option value="null" selected disabled>Ownership Type</option>
                                        <option value="Single">Single</option>
                                        <option value="Freehold">Freehold</option>
                                        <option value="Joint">Joint</option>
                                        <option value="Co-operative Society">Co-operative Society</option>
                                        <option value="Builder">Builder</option>
                                        <option value="NA">NA</option>
                                    </select>

                                    <select name="property_category" style={{ height: 50 }}>
                                        <option value="null" selected disabled>Property Category</option>
                                        <option value="Residential">Residential</option>
                                        <option value="Commercial">Commercial</option>
                                    </select>

                                    <select name="property_type" style={{ height: 50 }}>
                                        <option value="null" selected disabled>Property Type</option>
                                        <option value="Apartment">Apartment/Flat</option>
                                        <option value="Independent House">Independent House</option>
                                        <option value="Villa">Villa</option>
                                        <option value="Residential Plot">Residential Plot</option>
                                        <option value="Residential Studio">Residential Studio</option>
                                        <option value="Pent House">Pent House</option>
                                        <option value="Builder Floor">Builder Floor</option>
                                        <option value="Land">Land</option>
                                        <option value="Office Space">Office Space</option>
                                        <option value="Commercial Studio">Commercial Studio</option>
                                        <option value="Shop">Shop</option>
                                        <option value="Showroom">Showroom</option>
                                        <option value="Warehouse">Warehouse</option>
                                        <option value="Industrial Plot">Industrial Plot</option>
                                        <option value="Office Space in IT/SEZ">Office Space in IT/SEZ</option>
                                    </select>

                                    <select name="state" style={{ height: 50 }}>
                                        <option value="null" selected disabled>Select State</option>
                                        <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                        <option value="Andhra Pradesh">Andhra Pradesh</option>
                                        <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                        <option value="Assam">Assam</option>
                                        <option value="Bihar">Bihar</option>
                                        <option value="Chandigarh">Chandigarh</option>
                                        <option value="Chhattisgarh">Chhattisgarh</option>
                                        <option value="Dadra and Nagar Haveli and Daman and Diu">Dadra and Nagar Haveli and Daman and Diu</option>
                                        <option value="Delhi">Delhi</option>
                                        <option value="Goa">Goa</option>
                                        <option value="Gujarat">Gujarat</option>
                                        <option value="Haryana">Haryana</option>
                                        <option value="Himachal Pradesh">Himachal Pradesh</option>
                                        <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                        <option value="Jharkhand">Jharkhand</option>
                                        <option value="Karnataka">Karnataka</option>
                                        <option value="Kerala">Kerala</option>
                                        <option value="Ladakh">Ladakh</option>
                                        <option value="Lakshadweep">Lakshadweep</option>
                                        <option value="Madhya Pradesh">Madhya Pradesh</option>
                                        <option value="Maharashtra">Maharashtra</option>
                                        <option value="Manipur">Manipur</option>
                                        <option value="Meghalaya">Meghalaya</option>
                                        <option value="Mizoram">Mizoram</option>
                                        <option value="Nagaland">Nagaland</option>
                                        <option value="Odisha">Odisha</option>
                                        <option value="Puducherry">Puducherry</option>
                                        <option value="Punjab">Punjab</option>
                                        <option value="Rajasthan">Rajasthan</option>
                                        <option value="Sikkim">Sikkim</option>
                                        <option value="Tamil Nadu">Tamil Nadu</option>
                                        <option value="Telangana">Telangana</option>
                                        <option value="Tripura">Tripura</option>
                                        <option value="Union territories">Union territories</option>
                                        <option value="Uttar Pradesh">Uttar Pradesh</option>
                                        <option value="Uttarakhand">Uttarakhand</option>
                                        <option value="West Bengal">West Bengal</option>
                                        <option value="Other">Other</option>
                                    </select>

                                    <select name='city' style={{ height: 50 }}>
                                        <option value="null" selected disabled>Select City</option>
                                        <option value="bangalore">Bangalore</option>
                                        <option value="mumbai">Mumbai</option>
                                        <option value="delhi">Delhi</option>
                                        <option value="hyderabad">Hyderabad</option>
                                        <option value="chennai">Chennai</option>
                                        <option value="gurgoan">Gurgoan</option>
                                        <option value="noida">Noida</option>
                                    </select>

                                    <input type="text" name="locality" placeholder='Locality'></input>
                                    <input type="text" name="address" placeholder='Address'></input>
                                    <input type="number" name="pin_code" placeholder='Pincode'></input>
                                    <input type="text" name="zone" placeholder='Zone'></input>
                                    <input type="number" name="balcony_count" placeholder='Balcony Count'></input>
                                    <input type="number" name="booking_amount" placeholder='Booking Amount'></input>
                                    <input type="number" name="expected_sale_rent_value" placeholder='Expected Sale/Rent Value'></input>
                                    <input type="number" name="maintenance_charges" placeholder='Maintenance Charges'></input>
                                    <input type="number" name="flats_on_floor" placeholder='Flats On Floor'></input>
                                    <input type="text" name="URL_link" placeholder='URL Link'></input>
                                    <input type="text" name="car_parking_slot_number" placeholder='Car Parking Slot Number'></input>

                                    <select name="facing_direction" style={{ height: 50 }}>
                                        <option value="null" selected disabled>Facing Direction</option>
                                        <option value="East">East</option>
                                        <option value="North">North</option>
                                        <option value="South">South</option>
                                        <option value="West">West</option>
                                        <option value="North East">North East</option>
                                        <option value="North West">North West</option>
                                        <option value="South East">South East</option>
                                        <option value="South West">South West</option>
                                    </select>

                                    <select name="furnish_type" style={{ height: 50 }}>
                                        <option value="null" selected disabled>Furnish Type</option>
                                        <option value="Furnished">Furnished</option>
                                        <option value="Semi-Furnished">Semi-Furnished</option>
                                        <option value="Unfurnished">Unfurnished</option>
                                    </select>

                                    {/* <input type="text" name="property_type" placeholder='Property Type'></input> */}

                                    <input type="text" name="title" placeholder='Title'></input>
                                    <input type="text" name="car_parking" placeholder='Car Parking'></input>
                                    <input type="text" name="property_value_by_customer" placeholder='property value by customer'></input>
                                    <input type="text" name="description" placeholder='Description'></input>
                                    <input type="text" name="portal_info" placeholder='Portal Info'></input>
                                    <input type="text" name="house_no" placeholder='Property #'></input>
                                    <input type="number" name="bedroom_count" placeholder='Bedrooom Count'></input>
                                    <input type="text" name="bedroom_size" placeholder='Bedroom Size'></input>
                                    <input type="text" name="lift" placeholder='Lift'></input>
                                    <input type="text" name="survey_no" placeholder='survey No'></input>
                                    <input type="number" name="bathroom" placeholder='Bathroom Count'></input>
                                    <input type="text" name="transaction_type" placeholder='Transaction Type'></input>
                                    <input type="text" name="society_name" placeholder='Society Name'></input>
                                    <input type="text" name="website" placeholder='Website'></input>
                                    <input type="number" name="plot_area" placeholder='Plot Area'></input>
                                    <input type="number" name="built_up_area" placeholder='Built Up Area(Sqft)'></input>
                                    <input type="text" name="property_age" placeholder='Property Age'></input>
                                    <input type="text" name="area" placeholder='Area'></input>
                                    <input type="text" name="car_parking_open" placeholder='No. Of Open Car Parking'></input>
                                    <input type="text" name="car_parking_covered" placeholder='No. Of Covered Car Parking'></input>
                                    <input type="text" name="additional_facilities" placeholder='Additional Facilities'></input>
                                    <input type="text" name="property_purchased" placeholder='Property Purchased'></input>
                                    <input type="text" name="property_purchased_value" placeholder='Property Purchased Value'></input>
                                    <input type="text" name="landmark" placeholder='Landmark'></input>
                                    <input type="text" name="floor_count" placeholder='Floor Count'></input>
                                    <input type="text" name="dimension_length" placeholder='Dimension Length'></input>
                                    <input type="text" name="dimension_width" placeholder='Dimension Width'></input>
                                    <input type="text" name="gps_location" placeholder='GPS Location'></input>
                                    <input type="text" name="requirements" placeholder='Requirements'></input>
                                    <input type="text" name="property_status" placeholder='Property Status'></input>
                                    <input type="text" name="property_number" placeholder='Property Number'></input>
                                    <input type="text" name="prop_remarks" placeholder='Property Remarks'></input>
                                    <input type="text" name="uds" placeholder='UDS'></input>

                                    <FormControl className={classes.formControl}>
                                        <InputLabel id="demo-mutiple-amenities-label">Amenities</InputLabel>
                                        <Select
                                            labelId="demo-mutiple-amenities-label"
                                            id="demo-mutiple-name"
                                            multiple
                                            value={availableamenities}
                                            onChange={handleChange}
                                            input={<Input />}
                                            MenuProps={MenuProps}
                                        >
                                            {amenities.map((amenities) => (
                                                <MenuItem key={amenities} value={amenities} style={getStyles(amenities, availableamenities, theme)}>
                                                    {amenities}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {/* <TextField label="Dense" id="outlined-margin-dense" defaultValue="Default Value" className={classes.textField} helperText="Some important text"  margin="dense" variant="outlined"/> */}
                                </form>
                            </div>
                        </div>
                    </div>
                    <div><button className={styles.Nextbtn} onClick={apipost}>Submit</button></div>
                    {/* <div><button className={styles.LogoutBtn} onClick={Logout}>Logout</button></div> */}
                </div>
            </div>
        </div>
    );
}

export default Propertydetails;


