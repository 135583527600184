import React from 'react';
import SearchIcon from '../../assets/images/Vector (2).png';
import styles from './style.module.css';

const Search = () => {
    return (
        <div style = {{position:"relative"}}>
            <img src = {SearchIcon} className = {styles.imageSize} />
            <input type = "text" className = {styles.SearchBox} placeholder = {"Search properties,Services"} />
        </div>
    )
}

export default Search
