import styles from './requests.module.css';
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useNavigate } from 'react-router-dom';
import SideBarCard from '../../../components/SideBarCard';
import { SideBar } from '../../../constants';
import logoImage from '../../../assets/images/webimg/logo.svg';
import { useParams } from 'react-router-dom';
// import Pagination from '@material-ui/lab/Pagination';

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
</style>


const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
    },

    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));

<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="#">Navbar</a>

</nav>

function Customerproperty() {

    const Navigate = useNavigate();
    const idcustomer = useParams();
    const [totalproperties, settotalproperties] = useState([]);

    const capitalizeFirst = str => {
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    };

    const classes = useStyles();
    const [login, setLogin] = useState(false);
    const [openloader, setOpenLoader] = useState(false)
    const custpropertydetails = () => {

        if (Location.state !== "") {
            fetch("https://dashboard.infinity.indiassetz.com/infinity/api/v1/dashboard/customer/" + idcustomer.custid + "/property").then((response) => {
                response.json().then((result) => {
                settotalproperties(result[0].properties);
            })
            }).catch(
                (err) => {
                    Navigate("../customers");
                    // console.log("FAIL!")
                }
            )
        }
        else {
            Navigate("../customers");
        }
    }

    useEffect(() => {
        checklogin();
        custpropertydetails();
        // fetchapi("offset=0");
    }, [])

    function checklogin() {
        if (sessionStorage.getItem("login")) {
            setOpenLoader(true)
            let token = "Bearer " + sessionStorage.getItem('login');
            fetch("https://valuation-react-api.infinity-staging.indiassetz.com/infinity_api/v1/users/registered-users/", {
                method: "GET",
                headers: {
                    // 'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                    'Authorization': token
                }
                // body: JSON.stringify(this.state.post)
            }).then((response) => {
                response.json().then((result) => {

                    if (result.user_id != "") {
                        sessionStorage.setItem('name', capitalizeFirst(result.first_name) + " " + capitalizeFirst(result.last_name))
                        setLogin(true);
                        setOpenLoader(false)
                    }
                })
            }).catch(
                (err) => {
                    alert(err.message)
                    setOpenLoader(false)
                    Navigate('../login');
                }
            )
        }
        else {
            Navigate('../login');
        }
    }

    const getShadow = (index) => {
        const obj = {};
        return obj[index];
    }

    const getBackground = (index) => {
        const obj = {};
        return obj[index];
    }

    const Logout = () => {
        sessionStorage.clear();
        setLogin(false);
        Navigate('../login');
    }

    // const [pagecount, setpagecount] = useState()
    // // const [itemperpage, setitemperpage] = useState(10)
    // // const [filterobj, setfilterobj] = useState(initialFilter)
    // // const [customers,setcustomers] = useState();

    // fetch("https://dashboard.infinity.indiassetz.com/infinity/api/v1/dashboard/customer/" + idcustomer + "+/insights").then((response) => {
    //     response.json().then((result) => {

    //         //  setDemoNum(result[0]);
    //         let pagecount = result.total_properties / 10;
    //         setpagecount(Math.ceil(pagecount))
    //     })
    // }).catch(
    //     (err) => {
    //         console.log("FAIL!")
    //     }
    // )

    // function fetchapi(data) {
    //     if (apiUrl != "") {
    //         setOpenLoader(true)

    //         fetch(apiUrl + data)
    //             .then(response => response.json())
    //             .then(data => {
    //                 setcustomerapi(data[0].customers);
    //                 setOpenLoader(false);
    //             })
    //     }
    // }

    // function handlepagination(e, value) {

    //     let offset = (value * 10) - 10;
    //     let offsetstring = "offset=" + offset;
    //     fetchapi(offsetstring);
    // }

    return (

        <div>
            <Backdrop className={classes.backdrop} open={openloader}>
                <CircularProgress color="inherit" />
            </Backdrop>


            <div className={styles.Dashboard}>
                <div className={styles.leftSideScreen}>
                    <img src={logoImage} width="150" height="70" className={styles.leftSideImage} />
                    {SideBar.map((box, index) => {
                        return (
                            <SideBarCard name={box} boxShadow={getShadow(index)} backgroundColor={getBackground(index)} color={(box === "Dashboard" || box === "Properties" || box === "Order History") ? "#171717" : "#171717"} />
                        )
                    })}
                    <div><button className={styles.LogoutBtn} onClick={Logout}>Logout</button></div>
                    {/* <div className={styles.buttonStyle}>
                        <div>Add Property</div><div><img className={styles.VectorPlusImage} src={VectorPlusImage} /></div>
                    </div> */}
                </div>

                <div className={styles.rightSideScreen}>
                    <div className={styles.requests}>
                        <div className={styles.table}>
                            <div className={styles.tablehead}>
                                {/* <div className={styles.col0 + ' ' + styles.columns}><input type='checkbox'></input></div>
                                <div className={styles.col1 + ' ' + styles.columns}>Action</div> */}
                                <div className={styles.col1 + ' ' + styles.columns}>Property ID</div>
                                <div className={styles.col2 + ' ' + styles.columns}>Locality</div>
                                <div className={styles.col3 + ' ' + styles.columns}>Bedroom Count</div>
                                <div className={styles.col4 + ' ' + styles.columns}>Built Up Area</div>
                                <div className={styles.col5 + ' ' + styles.columns}>City</div>
                                <div className={styles.col6 + ' ' + styles.columns}>Country</div>
                                <div className={styles.col7 + ' ' + styles.columns}>Facing Direction</div>
                                <div className={styles.col8 + ' ' + styles.columns}>Furnish</div>
                                <div className={styles.col9 + ' ' + styles.columns}>Property Type</div>
                                <div className={styles.col10 + ' ' + styles.columns}>State</div>
                                <div className={styles.col11 + ' ' + styles.columns}>Title</div>
                                <div className={styles.col12 + ' ' + styles.columns}>Society Name</div>
                                <div className={styles.col13 + ' ' + styles.columns}>Property Category</div>
                                <div className={styles.col14 + ' ' + styles.columns}>Address</div>
                                <div className={styles.col15 + ' ' + styles.columns}>Is Active</div>
                                
                                {/* <div className={styles.col11 + ' ' + styles.columns}>registration_fee</div>
                                <div className={styles.col12 + ' ' + styles.columns}>fee_paid_date</div> */}
                            </div>

                            {totalproperties && totalproperties.map(item => (
                                <>
                                    {/* {item.is_active && !item.is_deleted && */}
                                    <div onClick={() => { Navigate('../detailsproperty', { state: item.property_id  }); }}>
                                    <div className={styles.tablerows}>
                                        {/* // <div className={styles.tablerows}> */}
                                        {/* <div className={styles.col0 + ' ' + styles.columns}><input type='checkbox'></input></div>
                                    <div className={styles.col1 + ' ' + styles.columns}>View</div> */}
                                        <div className={styles.col1 + ' ' + styles.columns}>{item.property_id}</div>
                                        <div className={styles.col2 + ' ' + styles.columns}>{item.locality}</div>
                                        <div className={styles.col3 + ' ' + styles.columns}>{item.bedroom_count}</div>
                                        <div className={styles.col4 + ' ' + styles.columns}>{item.built_up_area}</div>
                                        <div className={styles.col5 + ' ' + styles.columns}>{item.city}</div>
                                        <div className={styles.col6 + ' ' + styles.columns}>{item.country}</div>
                                        <div className={styles.col7 + ' ' + styles.columns}>{item.facing_direction}</div>
                                        <div className={styles.col8 + ' ' + styles.columns}>{item.furnish_type}</div>
                                        <div className={styles.col9 + ' ' + styles.columns}>{item.property_type}</div>
                                        <div className={styles.col10 + ' ' + styles.columns}>{item.state}</div>
                                        <div className={styles.col11 + ' ' + styles.columns}>{item.title}</div>
                                        <div className={styles.col12 + ' ' + styles.columns}>{item.society_name}</div>
                                        <div className={styles.col13 + ' ' + styles.columns}>{item.property_category}</div>
                                        <div className={styles.col14 + ' ' + styles.columns}>{item.address}</div>
                                        <div className={styles.col15 + ' ' + styles.columns}>{item.is_active}</div>
                                    </div>
                                    </div>
                                    {/* } */}
                                </>
                            ))}
                        </div>

                        {/* <center>
                            <div class='pagination_color'>
                                <div className={classes.root}>
                                    <Pagination count={pagecount} onChange={handlepagination} /> <br></br>
                                </div>
                            </div>
                        </center> */}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Customerproperty;


