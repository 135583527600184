import styles from './requests.module.css';
import React, { useState, useEffect } from 'react';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { useNavigate, useLocation } from 'react-router-dom';
import SideBarCard from '../../../components/SideBarCard';
import { SideBar } from '../../../constants';
import logoImage from '../../../assets/images/webimg/logo.svg';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

<style>
    @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@700&display=swap');
</style>

const useStyles = makeStyles((theme) => ({
    root: {
        '& > *': {
            marginTop: theme.spacing(2),
        },
    },

    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    formControl: {
        margin: theme.spacing(1),
        width: "80% !important",
        backgroundColor: "#FFFFFF",
        border: "1px solid #000000",
        borderRadius: "5px"
    },
    chips: {
        display: 'flex',
        flexWrap: 'wrap',
    },
    chip: {
        margin: 2,
    },
    noLabel: {
        marginTop: theme.spacing(3),
    },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

const amenities = [
    'Gym',
    'Swimming Pool',
    'Intercom',
    'Security Camera',
    'Indoor Games',
    'Park',
    'Tennis Court',
    'Event Hall',
    'Security',
    'Club House',
];

function getStyles(name, personName, theme) {
    return {
        fontWeight:
            personName.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

<nav class="navbar navbar-expand-lg navbar-light bg-light">
    <a class="navbar-brand" href="#">Navbar</a>
</nav>

function Detailsproperty() {

    const theme = useTheme();

    const [propertydetails, setdetails] = useState();
    const [availableamenities, setavailableamenities] = React.useState([]);
    const [disableedit, setdisable] = useState(false);

    const checkedit = (item) => {
        // console.log(item);
        if (item) {
            for (let i = 0; i < document.querySelectorAll('input[type=text]').length; i++) {
                document.querySelectorAll('input[type=text]')[i].disabled = "";
            }

            for (let i = 0; i < document.querySelectorAll('input[type=number]').length; i++) {
                document.querySelectorAll('input[type=number]')[i].disabled = "";
            }

            for (let i = 0; i < document.getElementsByTagName('select').length; i++) {
                document.getElementsByTagName('select')[i].disabled = "";
            }
        }

        else {

            for (let i = 0; i < document.querySelectorAll('input[type=text]').length; i++) {
                document.querySelectorAll('input[type=text]')[i].disabled = "true";
            }

            for (let i = 0; i < document.querySelectorAll('input[type=number]').length; i++) {
                document.querySelectorAll('input[type=number]')[i].disabled = "true";
            }

            for (let i = 0; i < document.getElementsByTagName('select').length; i++) {
                document.getElementsByTagName('select')[i].disabled = "true";
            }

        }
        setdisable(!item);
    }
    const getdetails = () => {

        if (Location.state > 0) {
            fetch("https://dashboard.infinity.indiassetz.com/infinity/api/v1/dashboard/property/" + idproperty).then((response) => {
                response.json().then((result) => {
                    setdetails(result[0].properties[0]);
                    // console.log(result[0].properties[0]);
                    setavailableamenities(result[0].properties[0].amenities_available.split(","));
                    //  setcustomers(result.total_customers);
                    //  setproperties(result.total_properties);
                    //  setrequests(result.total_services);
                    //  setlistings(result.total_services);
                    checkedit(false);
                })
            }).catch(
                (err) => {
                    Navigate("../properties");
                    // console.log("FAIL!")
                }
            )
        }
        else {
            Navigate("../properties");
        }
    }
    const handleChange = (event) => {
        setavailableamenities(event.target.value);
    };

    const Navigate = useNavigate();
    const Location = useLocation();
    const idproperty = Location.state;
    
    const capitalizeFirst = str => {
        return str.charAt(0).toUpperCase() + str.slice(1);
    };

    const classes = useStyles();
    const [login, setLogin] = useState(false);
    const [openloader, setOpenLoader] = useState(false)
    // console.log(Date.now())
    
    const updatefunction = () => {

        const form = document.querySelector('#postform');
        const formData = new FormData(form);

        const formDataObj = {};
        formData.append('prop_id', idproperty);
        // formData.append('bedroom_count', 3);
        // formData.append('built_up_area', 2100.00);
        // formData.append('bathroom', 3);
        // formData.append('ia_valuation', 0.00);
        // formData.append('country', 'India');
        // formData.append('property_category', 'Residential');
        // formData.append('property_type', 'Apartment');
        formData.append('is_checklist_apartment_cost', true);
        formData.append('is_checklist_clearances', true);
        formData.append('is_checklist_commencement_occupation_certificate', true);
        formData.append('is_checklist_financial_assistance', true);
        formData.append('is_checklist_issuance_receipt', true);
        formData.append('is_checklist_khata_extract', true);
        formData.append('is_checklist_parental_title_deeds', true);
        formData.append('is_checklist_plan_license', true);
        formData.append('is_checklist_possession_certificate', true);
        formData.append('is_checklist_power_attorney', true);
        formData.append('is_checklist_sale_construction_agreement', true);
        formData.append('is_checklist_sale_deed', true);
        formData.append('is_checklist_tax_paid_receipt', true);
        formData.append('is_hard_deleted', false);
        formData.append('is_public', false);
        formData.append('is_published', false);
        formData.append('infinity_updated_at', new Date().toISOString());
        formData.append('is_active', true);
        formData.append('is_deleted', false);
        // append static data here
        formData.append('amenities_available', availableamenities);

        // formData.append('keyname',"21")
        // formData.append('keyname',21)
        // formData.append('keyname',null)
        // formData.append('keyname',true)

        formData.forEach((value, key) => (formDataObj[key] = value));
        // console.log(formDataObj);
        // console.log(Date.now())

        fetch('https://dashboard.infinity.indiassetz.com/infinity/api/v1/dashboard/properties', {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(formDataObj)
        })
        // console.log(formDataObj)

            // Converting to JSON
            // handle based on status
            // .then(response =>
            //     response.json()
            // )

            // Displaying results to console
            .then(response => response.json())

            .then(json => {
                if (json.message == "property has been updated successfully") {
                    alert("Successfully updated!");
                    Navigate('../properties');
                }
                else {
                    alert("property not updated");
                }
            })
    }

    const deletefunction = () => {
        if (window.confirm("Are you sure you want to Delete!") == true) {
            // console.log("Deleted");
            // DELETE API
            fetch('https://dashboard.infinity.indiassetz.com/infinity/api/v1/dashboard/properties', {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({
                    "prop_id": idproperty,
                    "is_active": false,
                    "is_deleted": true
                }
                )
            })
                .then(response => response.json())

                .then(json => {
                    if (json.message == "property has been deleted successfully") {
                        alert("Successfully Deleted!");
                        Navigate('../properties');
                    }
                    else {
                        alert("property not deleted");
                        // Navigate('../properties');
                    }
                })
        }
        else {
            console.log("Not Deleted");

        }
    }

    useEffect(() => {
        checklogin();
        getdetails();
        // handletype(0);
    }, [])

    function checklogin() {
        if (sessionStorage.getItem("login")) {
            setOpenLoader(true)
            let token = "Bearer " + sessionStorage.getItem('login');
            fetch("https://valuation-react-api.infinity-staging.indiassetz.com/infinity_api/v1/users/registered-users/", {
                method: "GET",
                headers: {
                    // 'Accept': 'application/json',
                    // 'Content-Type': 'application/json',
                    'Authorization': token
                }
                // body: JSON.stringify(this.state.post)
            }).then((response) => {
                response.json().then((result) => {

                    if (result.user_id != "") {
                        sessionStorage.setItem('name', capitalizeFirst(result.first_name) + " " + capitalizeFirst(result.last_name))

                        setLogin(true);
                        setOpenLoader(false)
                    }
                })
            }).catch(
                (err) => {
                    alert(err.message)
                    setOpenLoader(false)
                    Navigate('../login');

                }
            )
        }
        else {
            Navigate('../login');
        }
    }

    const getShadow = (index) => {
        const obj = {};
        return obj[index];
    }

    const getBackground = (index) => {
        const obj = {};
        return obj[index];
    }

    const Logout = () => {
        sessionStorage.clear();
        setLogin(false);
        Navigate('../login');
    }

    return (

        <div>
            <Backdrop className={classes.backdrop} open={openloader}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <div className={styles.Dashboard}>
                <div className={styles.leftSideScreen}>
                    <img src={logoImage} width="150" height="70" className={styles.leftSideImage} />
                    {SideBar.map((box, index) => {
                        return (
                            <SideBarCard name={box} boxShadow={getShadow(index)} backgroundColor={getBackground(index)} color={(box === "Dashboard" || box === "Properties" || box === "Order History") ? "#171717" : "#171717"} />
                        )
                    })}
                    {/* <div className={styles.buttonStyle}>
                        <div>Add Property</div><div><img className={styles.VectorPlusImage} src={VectorPlusImage} /></div>
                    </div> */}
                    {/* <div className={styles.LogoutBtn}> */}
                    <div><button className={styles.LogoutBtn} onClick={Logout}>Logout</button></div>
                    {/* </div> */}
                </div>

                <div className={styles.rightSideScreen}>
                    <div className={styles.companyDetails}>
                        <div className={styles.flex_container}>
                            <div className={styles.rightdiv}>

                                {propertydetails &&

                                    <form className={styles.inputform} id="postform" name='postform'>

                                        <div className={styles.labeldiv}>
                                            Ownership Type
                                            <select name="ownership_type" placeholder='Ownership Type' defaultValue={propertydetails.ownership_type} style={{ height: 50 }}>
                                                <option value="" disabled>Ownership Type</option>
                                                <option value="Single">Single</option>
                                                <option value="Freehold">Freehold</option>
                                                <option value="Joint">Joint</option>
                                                <option value="Co-operative Society">Co-operative Society</option>
                                                <option value="Builder">Builder</option>
                                                <option value="NA">NA</option>
                                            </select>
                                        </div>

                                        <div className={styles.labeldiv}>
                                            Propery Category
                                            <select name="property_category" defaultValue={propertydetails.property_category} placeholder='Property Category' style={{ height: 50 }} >
                                                <option value="" disabled>Property Category</option>
                                                <option value="Residential">Residential</option>
                                                <option value="Commercial">Commercial</option>
                                            </select>
                                        </div>

                                        <div className={styles.labeldiv}>
                                            Propery Type
                                            <select name="property_type" defaultValue={propertydetails.property_type} placeholder='Property Type' style={{ height: 50 }}>
                                                <option value="" disabled>Property Type</option>
                                                <option value="Apartment">Apartment/Flat</option>
                                                <option value="Independent House">Independent House</option>
                                                <option value="Villa">Villa</option>
                                                <option value="Residential Plot">Residential Plot</option>
                                                <option value="Residential Studio">Residential Studio</option>
                                                <option value="Pent House">Pent House</option>
                                                <option value="Builder Floor">Builder Floor</option>
                                                <option value="Land">Land</option>
                                                <option value="Office Space">Office Space</option>
                                                <option value="Commercial Studio">Commercial Studio</option>
                                                <option value="Shop">Shop</option>
                                                <option value="Showroom">Showroom</option>
                                                <option value="Warehouse">Warehouse</option>
                                                <option value="Industrial Plot">Industrial Plot</option>
                                                <option value="Office Space in IT/SEZ">Office Space in IT/SEZ</option>
                                            </select>
                                        </div>

                                        <div className={styles.labeldiv}>
                                            State
                                            <select name="state" style={{ height: 50 }} defaultValue={propertydetails.state}>
                                                <option value="" disabled>Select State</option>
                                                <option value="Andaman and Nicobar Islands">Andaman and Nicobar Islands</option>
                                                <option value="Andhra Pradesh">Andhra Pradesh</option>
                                                <option value="Arunachal Pradesh">Arunachal Pradesh</option>
                                                <option value="Assam">Assam</option>
                                                <option value="Bihar">Bihar</option>
                                                <option value="Chandigarh">Chandigarh</option>
                                                <option value="Chhattisgarh">Chhattisgarh</option>
                                                <option value="Dadra and Nagar Haveli and Daman and Diu">Dadra and Nagar Haveli and Daman and Diu</option>
                                                <option value="Delhi">Delhi</option>
                                                <option value="Goa">Goa</option>
                                                <option value="Gujarat">Gujarat</option>
                                                <option value="Haryana">Haryana</option>
                                                <option value="Himachal Pradesh">Himachal Pradesh</option>
                                                <option value="Jammu and Kashmir">Jammu and Kashmir</option>
                                                <option value="Jharkhand">Jharkhand</option>
                                                <option value="Karnataka">Karnataka</option>
                                                <option value="Kerala">Kerala</option>
                                                <option value="Ladakh">Ladakh</option>
                                                <option value="Lakshadweep">Lakshadweep</option>
                                                <option value="Madhya Pradesh">Madhya Pradesh</option>
                                                <option value="Maharashtra">Maharashtra</option>
                                                <option value="Manipur">Manipur</option>
                                                <option value="Meghalaya">Meghalaya</option>
                                                <option value="Mizoram">Mizoram</option>
                                                <option value="Nagaland">Nagaland</option>
                                                <option value="Odisha">Odisha</option>
                                                <option value="Puducherry">Puducherry</option>
                                                <option value="Punjab">Punjab</option>
                                                <option value="Rajasthan">Rajasthan</option>
                                                <option value="Sikkim">Sikkim</option>
                                                <option value="Tamil Nadu">Tamil Nadu</option>
                                                <option value="Telangana">Telangana</option>
                                                <option value="Tripura">Tripura</option>
                                                <option value="Union territories">Union territories</option>
                                                <option value="Uttar Pradesh">Uttar Pradesh</option>
                                                <option value="Uttarakhand">Uttarakhand</option>
                                                <option value="West Bengal">West Bengal</option>
                                                <option value="Other">Other</option>
                                            </select>
                                        </div>


                                        <div className={styles.labeldiv}>
                                            City
                                            <select name='city' style={{ height: 50 }} defaultValue={propertydetails.city}>
                                                <option value="" disabled>Select City</option>
                                                <option value="bangalore">Bangalore</option>
                                                <option value="mumbai">Mumbai</option>
                                                <option value="bangalore">Delhi</option>
                                                <option value="mumbai">Hyderabad</option>
                                                <option value="bangalore">Chennai</option>
                                                <option value="mumbai">Gurgoan</option>
                                                <option value="bangalore">Noida</option>
                                            </select>
                                        </div>

                                        <div className={styles.labeldiv}>
                                            Locality
                                            <input type="text" name="locality" placeholder='Locality' defaultValue={propertydetails.locality}></input>
                                        </div>

                                        <div className={styles.labeldiv}>
                                            Address
                                            <input type="text" name="address" placeholder='Address' defaultValue={propertydetails.address}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Pin Code
                                            <input type="number" name="pin_code" placeholder='Pincode' defaultValue={propertydetails.pin_code}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Zone
                                            <input type="text" name="zone" placeholder='Zone' defaultValue={propertydetails.zone}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Balcony Count
                                            <input type="number" name="balcony_count" placeholder='Balcony Count' defaultValue={propertydetails.balcony_count}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Booking Amount
                                            <input type="number" name="booking_amount" placeholder='Booking Amount' defaultValue={propertydetails.booking_amount}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Expected Sale Rent Value
                                            <input type="number" name="expected_sale_rent_value" placeholder='Expected Sale/Rent Value' defaultValue={propertydetails.expected_sale_rent_value}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Maintenance Charges
                                            <input type="number" name="maintenance_charges" placeholder='Maintenance Charges' defaultValue={propertydetails.maintenance_charges}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Flats On Floor
                                            <input type="number" name="flats_on_floor" placeholder='Flats On Floor' defaultValue={propertydetails.flats_on_floor}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            URL link
                                            <input type="text" name="URL_link" placeholder='URL Link' defaultValue={propertydetails.URL_link}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Car Parking Slot Number
                                            <input type="text" name="car_parking_slot_number" placeholder='Car Parking Slot Number' defaultValue={propertydetails.car_parking_slot_number}></input>
                                        </div>

                                        <div className={styles.labeldiv}>
                                            Facing Direction
                                            <select name="facing_direction" style={{ height: 50 }} defaultValue={propertydetails.facing_direction}>
                                                <option value="" disabled>Facing Direction</option>
                                                <option value="East">East</option>
                                                <option value="North">North</option>
                                                <option value="South">South</option>
                                                <option value="West">West</option>
                                                <option value="North East">North East</option>
                                                <option value="North West">North West</option>
                                                <option value="South East">South East</option>
                                                <option value="South West">South West</option>
                                            </select>
                                        </div>

                                        <div className={styles.labeldiv}>
                                            Furnish Type
                                            <select name="furnish_type" style={{ height: 50 }} defaultValue={propertydetails.furnish_type}>
                                                <option value="" disabled>Furnish Type</option>
                                                <option value="Furnished">Furnished</option>
                                                <option value="Semi-Furnished">Semi-Furnished</option>
                                                <option value="Unfurnished">Unfurnished</option>
                                            </select>
                                        </div>

                                        {/* <input type="text" name="property_type" placeholder='Property Type'></input> */}

                                        <div className={styles.labeldiv}>
                                            Title
                                            <input type="text" name="title" placeholder='Title' defaultValue={propertydetails.title}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Car Parking
                                            <input type="text" name="car_parking" placeholder='Car Parking' defaultValue={propertydetails.car_parking}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Property Value By Customer
                                            <input type="text" name="property_value_by_customer" placeholder='property value by customer' defaultValue={propertydetails.property_value_by_customer}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Description
                                            <input type="text" name="description" placeholder='Description' defaultValue={propertydetails.description}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Portal Info
                                            <input type="text" name="portal_info" placeholder='Portal Info' defaultValue={propertydetails.portal_info}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            House No
                                            <input type="text" name="house_no" placeholder='Property #' defaultValue={propertydetails.house_no}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Bedroom Count
                                            <input type="number" name="bedroom_count" placeholder='Bedrooom Count' defaultValue={propertydetails.bedroom_count}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Bedroom Size
                                            <input type="text" name="bedroom_size" placeholder='Bedroom Size' defaultValue={propertydetails.bedroom_size}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Lift
                                            <input type="text" name="lift" placeholder='Lift' defaultValue={propertydetails.lift}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Survey No
                                            <input type="text" name="survey_no" placeholder='survey No' defaultValue={propertydetails.survey_no}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Bathroom
                                            <input type="number" name="bathroom" placeholder='Bathroom Count' defaultValue={propertydetails.bathroom}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Transaction Type
                                            <input type="text" name="transaction_type" placeholder='Transaction Type' defaultValue={propertydetails.transaction_type}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Society Name
                                            <input type="text" name="society_name" placeholder='Society Name' defaultValue={propertydetails.society_name}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Website
                                            <input type="text" name="website" placeholder='Website' defaultValue={propertydetails.website}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Plot Area
                                            <input type="text" name="plot_area" placeholder='Plot Area' defaultValue={propertydetails.plot_area}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Built Up Area
                                            <input type="number" name="built_up_area" placeholder='Built Up Area(Sqft)' defaultValue={propertydetails.built_up_area}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Property Age
                                            <input type="text" name="property_age" placeholder='Property Age' defaultValue={propertydetails.property_age}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Area
                                            <input type="text" name="area" placeholder='Area' defaultValue={propertydetails.area}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Car Parking Open
                                            <input type="text" name="car_parking_open" placeholder='No. Of Open Car Parking' defaultValue={propertydetails.car_parking_open}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Car Parking Covered
                                            <input type="text" name="car_parking_covered" placeholder='No. Of Covered Car Parking' defaultValue={propertydetails.car_parking_covered}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Additional Facilities
                                            <input type="text" name="additional_facilities" placeholder='Additional Facilities' defaultValue={propertydetails.additional_facilities}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Property Purchased
                                            <input type="text" name="property_purchased" placeholder='Property Purchased' defaultValue={propertydetails.property_purchased}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Property Purchased Value
                                            <input type="text" name="property_purchased_value" placeholder='Property Purchased Value' defaultValue={propertydetails.property_purchased_value}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Landmark
                                            <input type="text" name="landmark" placeholder='Landmark' defaultValue={propertydetails.landmark}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Floor Count
                                            <input type="text" name="floor_count" placeholder='Floor Count' defaultValue={propertydetails.floor_count}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Dimension Length
                                            <input type="text" name="dimension_length" placeholder='Dimension Length' defaultValue={propertydetails.dimension_length}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Dimension Width
                                            <input type="text" name="dimension_width" placeholder='Dimension Width' defaultValue={propertydetails.dimension_width}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            GPS Location
                                            <input type="text" name="gps_location" placeholder='GPS Location' defaultValue={propertydetails.gps_location}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            IA Valuation
                                            <input type="text" name="ia_valuation" placeholder='IA Valuation' defaultValue={propertydetails.ia_valuation}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Requirement
                                            <input type="text" name="requirement" placeholder='Requirement' defaultValue={propertydetails.requirement}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Property Status
                                            <input type="text" name="property_status" placeholder='Property Status' defaultValue={propertydetails.property_status}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Property Number
                                            <input type="text" name="property_number" placeholder='Property Number' defaultValue={propertydetails.property_number}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Prop Remarks
                                            <input type="text" name="prop_remarks" placeholder='Property Remarks' defaultValue={propertydetails.prop_remarks}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            UDS
                                            <input type="text" name="uds" placeholder='UDS' defaultValue={propertydetails.uds}></input>
                                        </div>
                                        <div className={styles.labeldiv}>
                                            Country
                                            <input type="text" name="country" placeholder='Country' defaultValue={propertydetails.country}></input>
                                        </div>

                                        <FormControl className={classes.formControl} disabled={disableedit}>
                                            <InputLabel id="demo-mutiple-amenities-label">Amenities</InputLabel>
                                            <Select
                                                labelId="demo-mutiple-amenities-label"
                                                id="demo-mutiple-name"
                                                multiple
                                                value={availableamenities}
                                                onChange={handleChange}
                                                input={<Input />}
                                                MenuProps={MenuProps}
                                            >
                                                {amenities.map((amenities) => (
                                                    <MenuItem key={amenities} value={amenities} style={getStyles(amenities, availableamenities, theme)}>
                                                        {amenities}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                        {/* <TextField label="Dense" id="outlined-margin-dense" defaultValue="Default Value" className={classes.textField} helperText="Some important text"  margin="dense" variant="outlined"/> */}
                                    </form>
                                }
                                {/* <button className={styles.Editbtn} onClick={apipost}>Edit</button>
                                <button className={styles.Deletebtn} onClick={apipost}>Delete</button> */}
                            </div>
                        </div>
                        <div className={styles.edit_delete}>
                            <button className={styles.Editbtn} onClick={() => { checkedit(disableedit) }} >Edit</button>
                            {
                                !disableedit &&
                                <button className={styles.Updatebtn} onClick={updatefunction}>UPDATE</button>
                            }
                            <button className={styles.Deletebtn} onClick={deletefunction}>Delete</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Detailsproperty;


